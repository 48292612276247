import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { UserService } from '../_services/user.service';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ AuthService ]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loginLoading: boolean = false;
  returnUrl: string;
  error: string;
  devMode: boolean = false;

  currentYear: number = new Date().getFullYear();

  userProjects;

  constructor(
    private http: HttpClient,
    private user: UserService,
    public auth: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    // Check environment
    if (environment.production) {
      this.devMode = false;
    } else {
      this.devMode = true;
    }
    console.log('Site in test mode: ' + this.devMode);

    if (this.auth.authenticated()) {
      this.router.navigate(['welcome']);
    } else {
      this.buildLoginForm();
      this.returnUrl = this.route.snapshot.queryParams['return'];
    }
  }

  buildLoginForm() {
    this.loginForm = this.formBuilder.group({    
      username: ['', Validators.required],
      password: ['', [Validators.required]]
    });
  }

  login() {
    this.loginLoading = true;
    this.error = null;
    this.user.getToken(this.loginForm.value.username, this.loginForm.value.password).subscribe(
      r => { 
        // Decode token
        let userToken = this.auth.decode(r.data.token);
        // Save token to localStorage
        localStorage.setItem('mhlUserToken', r.data.token);
        // Navigate to return URL or direct to welcome
        this.projects(userToken);
      },
      e => { 
        console.log(e);
        this.loginLoading = false;
        this.error = "Incorrect email or password. Please try again."
      }
    );
  }

  projects(userToken) {

    // Get project information for user projects
    let projects = [
      { projectId: 1, projectName: "Mountain Healthcare Reporting Tool" },
      { projectId: 2, projectName: "Police Benchmarking Network" }
    ]
    let userProjects = projects.filter(p => userToken.userProjectList.includes(p.projectId));

    // Remove spinner
    this.loginLoading = false;

    // Auto-navigate or show selector
    if (userProjects.length === 1) {
      if (this.returnUrl) {
        this.router.navigateByUrl(this.returnUrl);
      } else {
        this.navigate(userProjects[0].projectId);
      }
    } else {
      this.userProjects = userProjects;
    }
    
  }

  navigate(projectId) {
    if (projectId == 1) {
      this.router.navigate(['welcome']);
    } else {
      this.router.navigate(['project', projectId]);
    }
  }

}
