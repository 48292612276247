<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-md-10">
      <h2>Welcome to the SARC Operational Toolkit</h2>
      <h5 class="mb-4">
        This portal is designed to offer clear visualisations and analytics on the SARC data taken from the Mountain Healthcare operational reporting system.
      </h5>
      <p>
        The tool allows you to track case activity and key demographic information, both for an individual SARC and across your region or contract. It offers a variety of graphs to display this information and there are several filters you can apply to give you control over which data you view.        
      </p>
      <p>
        Please note this is the beta version of the tool, so if you find anything which does not work as expected or there are any issues with the data then please use the link provided in the menu bar above to report it.
      </p>
    </div>
  </div>
</div>