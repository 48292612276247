import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { DataService } from '../_services/data.service';

// Pipes
import { GroupByPipe, OrderByPipe, UniquePipe, SumPipe } from 'ngx-pipes';

// Packages
import * as Highcharts from 'highcharts';
import * as moment from 'moment';

@Component({
  selector: 'app-toolkit',
  templateUrl: './toolkit.component.html',
  styleUrls: ['./toolkit.component.scss'],
  providers: [ GroupByPipe, OrderByPipe, UniquePipe, SumPipe ],
  encapsulation: ViewEncapsulation.None
})
export class ToolkitComponent implements OnInit {

  projectId: number = 1;

  // User and organisation
  user;
  organisations; organisationTiers; organisation;
  permissionsError: boolean = false;

  // Menus
  organisationMenu: boolean = false;
  selectedMenu = {
    tiers: false,
    filters: false,
    dates: false,
    any: false
  };
  tiers; searchableTiers;
  colors: any = [
    "#e03616","#f1a208","#00a878","#111b54","#a01a7d","#09c2de",
    "#f39886","#fbd281","#54ffcf","#566adc","#e873c9","#78e8f9",
    "#6f1c0b","#795204","#00543c","#090e2a","#4f0d3d","#05606e"            
  ]; 

  // Report
  startDate; endDate;
  report; reportDetails; reportFilters; 
  caseData; caseDataDate;
  reportData; timeSeriesData;
  reportTable; reportTableTotals;
  reportError: string;
  noReportData: boolean = false;
  timeIntervalType: string = 'month';

  // Loading
  loadingReport: boolean = false;
  loadingCaseRefs: boolean = false;

  // Charts
  Highcharts: typeof Highcharts = Highcharts;
  timeSeriesChartOptions: Highcharts.Options; timeSeriesChart: any;
  lineChartOptions: Highcharts.Options; lineChart: any; legend;
  columnChartOptions: Highcharts.Options; columnChart: any;
  pieChartOptions: Highcharts.Options; pieChart: any;
  differenceChartOptions: Highcharts.Options; differenceChart: any;
  zoomed: boolean = false;

  // Filters
  dateFilterId;
  selectedFilters = [];
  selectedFiltersParams = [];
  filterValues = [];
  search;

  constructor(
    private data: DataService,
    public route: ActivatedRoute,
    public router: Router,
    public group: GroupByPipe,
    public order: OrderByPipe,
    public unique: UniquePipe,
    public sum: SumPipe
  ) { }

  ngOnInit() {
    this.getOrganisations(this.projectId);
  }

  ngOnDestroy() {
    sessionStorage.removeItem('mhlFilters');
  }

  // GET

  getOrganisations(projectId) {
    this.data.getOrganisations(projectId).subscribe(
      r => { 
        // Get and set organisation
        this.organisations = r.data.organisationList;

        //Check for saved organisation
        let savedOrganisation = localStorage.getItem('mhlOrganisation');
        if (savedOrganisation && savedOrganisation !== 'undefined') {
          this.organisation = this.organisations.find(o => o.organisationId == JSON.parse(savedOrganisation).organisationId);
        } else {
          this.organisation = this.organisations[0];
        }

        // Save to localStorage
        localStorage.setItem('mhlOrganisation', JSON.stringify(this.organisation));

        // Get dashboard data if organisation set
        if (this.organisation) {
          this.getTiers(projectId);
        } else {
          this.permissionsError = true;
        }
        
      },
      e => { 
        console.log(e);
        this.permissionsError = true;
      }
    )
  }

  getOrganisationTiers(projectId) {
    if (!this.organisationTiers) {
      this.data.getOrganisationTiers(projectId).subscribe(
        r => {
          this.organisationTiers = r.data.organisationHierarchy;
        },
        e => {
          console.log(e);
          this.permissionsError = true;
        }
      )
    }
  }

  getTiers(projectId) {
    this.data.getTiers(projectId).subscribe(
      r => {
        this.tiers = r.data.tierList;
        this.searchableTiers = this.formatTiers(r.data.tierList);

        // If report link, open report
        let reportId = +this.route.snapshot.queryParams.report;
        if (reportId) { this.getDetails(reportId, this.organisation.organisationId) };
      },
      e => {
        console.log(e);
        this.permissionsError = true;
      }
    )
  }

  getDetails(reportId, organisationId?) {
    this.setMenu('any');
    this.loadingReport = true;
    this.reportError = null;
    this.reportData = null;
    this.caseData = null;
    this.loadingReport = true;
    this.data.getDetails(reportId, organisationId).subscribe(
      r => {
        this.report = JSON.stringify(r.data);
        this.reportDetails = r.data.reportDetails;
        this.reportFilters = r.data.reportFilters;

        // Set correct filterId for dates
        let filterId = r.data.reportDetails.reportParameters.find(rp => rp.parameterType === 'T');
        if (filterId) {
          this.dateFilterId = filterId.dataItemId;
        } else {
          this.dateFilterId = null;
        }

        // If params saved in sessionStorage, set them
        let filters = JSON.parse(sessionStorage.getItem('mhlFilters'));
        if (filters) {
          // Check all selected filters exist and, if not, remove them before using
          this.selectedFilters = this.checkFilters(this.reportFilters, filters.selectedFilters, 'dataItemId');
          this.selectedFiltersParams = this.checkFilters(this.reportFilters, filters.selectedFiltersParams, 'filterId');
        }

        this.setLink(reportId);
        this.getData(reportId, this.organisation.organisationId, this.selectedFiltersParams);
      },
      e => {
        console.log(e);
        this.reportError = 'There has been an error loading the report information. Please try refreshing the page.'
        this.loadingReport = false;
      }
    )
  }

  getData(reportId, organisationId, filters?) {
    this.loadingReport = true;
    this.reportError = null;
    this.setMenu('any');
    this.data.getData(reportId, organisationId, filters).subscribe(
      r => {
        this.reportData = r.data.reportData;

        // Return no data
        if (!this.reportData.length) {
          this.noReportData = true;
          this.reportError = 'There is no data for this report and combination of filters. Click here to reset.'
        } else {
          this.noReportData = false;
        }

        // Set charts
        if (this.reportDetails.reportType === 'DATEDIFF') {
          this.setDifferenceCharts(reportId, this.reportData, filters);
        } else {
          this.setCategoryCharts(reportId, this.reportData, filters);
        }
        
      },
      e => {
        console.log(e);
        this.loadingReport = false;
        this.reportError = 'There has been an error loading the data. Please try refreshing the page.'
      }
    )
  }

  getTimeSeriesData(reportId, organisationId, filters?) {
    
    // TODO: Need to set this dynamically
    let timeInterval = 1;
    let timeIntervalType = 'day';

    this.data.getTimeSeriesData(reportId, organisationId, timeInterval, timeIntervalType, filters).subscribe(
      r => { 
        let reportData = r.data.reportTimeseries;

        // Format date
        reportData.forEach(rd => {
          rd.date = moment(rd.datePeriod).format('YYYY-MM-DD');
        });

        // Define date range
        let dates = reportData.map(d => moment(d.datePeriod)),
        minDate = moment.min(dates).format('YYYY-MM-DD'),
        maxDate = moment.max(dates).format('YYYY-MM-DD'),
        dateRange = this.getDateRange(minDate, maxDate);

        // Group by category
        let formattedData = this.group.transform(reportData, 'seriesName');

        let orderedData, lineSeries, lineCategories;

        if (this.reportDetails.reportType !== 'DATEDIFF') {

          orderedData = {};

          // Define dataRange for each category
          Object.keys(formattedData).forEach(c => {
            formattedData[c].daily = JSON.parse(JSON.stringify(dateRange));
          });

          // Add value for every date in dateRange
          Object.keys(formattedData).forEach(s => {
            formattedData[s].daily.forEach(dr => {
              let date = formattedData[s].find(ss => ss.date === dr.date);
              dr.count = date ? date.caseCount : null;
            });
          });

          // Match order of categories
          let categoriesOrder = this.reportData.map(rd => rd.seriesName);
          categoriesOrder.forEach(c => { 
            orderedData[c] = formattedData[c];
          });

          // Line chart
          lineSeries = Object.keys(orderedData).map(c => { return { name: c, data: orderedData[c].daily.map(sd => sd.count) } });
          lineCategories = dateRange.map(dr => dr.date);

        } else {
          orderedData = formattedData['Average'];
          lineSeries = [{ name: 'Average', data: orderedData.map(c => c.caseCount) }];
          lineCategories = dateRange.map(dr => dr.date);
        }

        // Line chart
        this.createLineChart(lineCategories, lineSeries);

        // Make variables accessible
        this.timeSeriesData = orderedData;
        this.startDate = minDate;
        this.endDate = maxDate;

        // Complete load
        this.loadingReport = false;

      },
      e => {
        console.log(e);
        this.loadingReport = false;
        this.reportError = 'There has been an error loading the data. Please try refreshing the page.'
      }
    )
  }

  getTimeSeriesData_new(reportId, organisationId, filters?, timeIntervalType?) {
    this.data.getTimeSeriesData(reportId, organisationId, 1, timeIntervalType, filters).subscribe(
      r => { 
        let reportData = r.data.reportTimeseries;

        // Define date range (filters take precedence over load)
        let startDate, endDate;
        let loadDates = reportData.map(d => moment(d.datePeriod));
        let filterStartDate = filters.find(f => f.filterType == 'dateFrom');
        let filterEndDate = filters.find(f => f.filterType == 'dateTo');
        let loadStartDate = moment.min(loadDates).format('YYYY-MM-DD');
        let loadEndDate = moment.max(loadDates).format('YYYY-MM-DD');
        startDate = filterStartDate ? filterStartDate.filterValue : loadStartDate;
        endDate = filterEndDate ? filterEndDate.filterValue : loadEndDate;

        // Set date range
        this.startDate = moment(startDate).format('YYYY-MM-DD');
        this.endDate = moment(endDate).format('YYYY-MM-DD');
        
        // Format dates
        if (reportData.length) {
          switch (timeIntervalType) {
            case 'month':
              reportData.forEach(rd => { rd.date = moment(rd.datePeriod).format('MMM-yy') });
              break;
            case 'day':
              reportData.forEach(rd => { rd.date = moment(rd.datePeriod).format('DD-MMM-YY') });
              break;
            case 'hour':
              reportData.forEach(rd => { rd.date = moment(rd.datePeriod).format('HH:mm') });
              break;
            default:
              console.log('No time interval type selected')
          }
        } else {
          return;
        }

        // Group by series
        reportData = this.group.transform(reportData, 'seriesName');

        // Add missing dates
        Object.keys(reportData).forEach(rd => {
          reportData[rd] = this.formatData(reportData[rd], rd, this.startDate, this.endDate, timeIntervalType);
        })

        // Define categories
        let categories = reportData[Object.keys(reportData)[0]].map(rd => rd.date);
        
        // Define data
        let data = [];
        Object.keys(reportData).forEach((rd, i) => {
          data.push({
            order: reportData[rd][0] ? reportData[rd][0].displaySequence : i + 1,
            name: rd,
            data: reportData[rd].map(d => d.caseCount)
          })
        })

        // Order by API display sequence
        data = this.order.transform(data, 'order');

        // Create chart
        this.createTimeSeriesChart(categories, data);

        // Complete load
        this.timeSeriesData = reportData;
        this.loadingReport = false;

      },
      e => {
        console.log(e);
        this.loadingReport = false;
        this.reportError = 'There has been an error retrieving data. Please try refreshing the page or selecting a different report.'
      }
    )

  }

  // SET

  setMenu(type) {
    this.search = null;
    if (type === 'any') {
      Object.keys(this.selectedMenu).forEach(m => {
        this.selectedMenu[m] = false;
      })
    } else {
      this.selectedMenu.any = !this.selectedMenu.any;
      this.selectedMenu[type] = !this.selectedMenu[type];
    }
  }

  setCategoryCharts(reportId, reportData, filters?) {

    // Column chart (raw data)
    let columnSeries = reportData.map((r, i) => { return { y: r.caseCount, color: this.colors[i] } } );
    let columnCategories = reportData.map(r => r.seriesName );
    this.createColumnChart(columnCategories, [{ name: this.reportDetails.reportName, data: columnSeries }]);  

    // Pie chart (% conversion)
    const totalCount: number = this.sum.transform(reportData.map(rd => rd.caseCount));
    let pieSeries = reportData.map((r, i) => { 
      return { 
        name: r.seriesName,
        count: r.caseCount,
        y: (r.caseCount / totalCount) * 100, 
        color: this.colors[i] 
      } 
    });
    this.createPieChart([{ name: this.reportDetails.reportName, colorByPoint: true, innerSize: '50%', data: pieSeries }]);

    // Create data table with totals
    const totalPerc: number = this.sum.transform(pieSeries.map(rd => rd.y));
    this.reportTable = pieSeries;
    this.reportTableTotals = {
      name: 'Totals',
      count: totalCount,
      y: totalPerc
    };

    // Time series data
    //this.getTimeSeriesData(reportId, this.organisation, filters);
    this.getTimeSeriesData_new(reportId, this.organisation.organisationId, filters, this.timeIntervalType);

  }

  setDifferenceCharts(reportId, reportData, filters?) {

    // Calculate average
    let sum: number = this.sum.transform(reportData.map(r => r.dateDifference)),
    count: number = reportData.length,
    average: number = sum / count;

    // Column chart (raw data)
    let columnSeries = reportData.map((r, i) => { return { y: r.dateDifference } } );
    let columnCategories = reportData.map(r => r.seriesName );
    this.createDifferenceChart(columnCategories, [{ name: this.reportDetails.reportName, data: columnSeries }], average);
    
    // Time series data
    this.getTimeSeriesData(reportId, this.organisation.organisationId, filters);

  }

  setLink(report) {
    // Save as param
    this.router.navigate([], { 
      relativeTo: this.route, 
      queryParams: { report: report }, 
      queryParamsHandling: 'merge'
    });

    let tiers = this.searchableTiers.filter(t => t.reportId === +report)[0].tierIdMap;
    
    let t1, t2, t3, t4;
    t1 = this.tiers.filter(t => t.tierId === tiers[0])[0];

    if (t1) {
      t1.selected = true;
      if (tiers.length > 1) {
        t2 = t1.childTiers.filter(t => t.tierId === tiers[1])[0];
        t2.selected = true;
        if (tiers.length > 2) {
          t3 = t2.childTiers.filter(t => t.tierId === tiers[2])[0];
          t3.selected = true;
          if (tiers.length > 3) {
            t4 = t3.childTiers.filter(t => t.tierId === tiers[3])[0];
            t4.selected = true;
          }
        }
      } 
    }
  }

  setFilter(dataItemId) {
    // Define filter
    let filter = this.reportFilters.find(rf => rf.dataItemId === +dataItemId);
    // Add filter
    this.selectedFilters.push(filter);
    // Remove from available filters
    this.reportFilters.splice(this.reportFilters.indexOf(filter), 1);
  }

  setFilterType(dataItemId, type) {
    // Define filter
    let filter = this.selectedFilters.find(rf => rf.dataItemId === dataItemId);
    // Set the type for this filter
    filter.selectedFilterType = type;
    // Remove all filter params for this filter
    this.selectedFiltersParams = this.selectedFiltersParams.filter(sfp => +sfp.filterId !== +filter.dataItemId);
    // Save to sessionStorage
    sessionStorage.setItem('mhlFilters', JSON.stringify({ selectedFilters: this.selectedFilters, selectedFiltersParams: this.selectedFiltersParams }));
  }

  setPreset(dataItemId, filterType, filterValue, type) {
    this.setFilter(dataItemId);
    this.setFilterType(dataItemId, type);
    this.updateFilter(dataItemId, filterType, filterValue);
  }

  // CREATE

  createLineChart(categories, series) {
    this.lineChartOptions = {
      chart: {
        type: 'line',
        zoomType: 'x', 
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: 'transparent',
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        },
      },
      colors: this.colors,
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{point.x}</div><table>',
        pointFormat: '<tr><td style="padding:0.25em 0;"><i class="fas fa-square mr-2" style="color:{series.color}"></i>{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 2,
        style: { color: '#FFFFFF' },
        shared: true
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        categories: categories,
        gridLineColor: '#EEF1F8'
      },
      yAxis: {
        gridLineColor: '#EEF1F8',
        title: {
          text: null
        },
        allowDecimals: false
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            enabled: false
          }
        }
      },
      series: series
    };
  }

  createTimeSeriesChart(categories, series) {
    this.timeSeriesChartOptions = {
      chart: {
        type: 'line',
        //zoomType: 'x', 
        animation: false,
        style: { 
          fontFamily: '"Helvetica Neue", Arial, sans-serif',
          cursor: 'pointer'
        },
        backgroundColor: 'transparent',
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        },
        events: {
          load: ((e) => { 
            this.timeSeriesChart = e.target;
          }),
          click: ((e) => {
            let categoryIndex = Math.ceil(e.xAxis[0].value);
            let categoryName = this.timeSeriesChart.axes[0].categories[categoryIndex];
            this.updateTimeSeries({ point: { category: categoryName } }, this.timeIntervalType);
          })
        },
      },
      colors: this.colors,
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{point.x}</div><table>',
        pointFormat: '<tr><td style="padding:0.25em 0;"><i class="fas fa-square mr-2" style="color:{series.color}"></i>{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 2,
        style: { color: '#FFFFFF' },
        shared: true
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        categories: categories,
        gridLineColor: '#EEF1F8',
        crosshair: true,
        events:{
          afterSetExtremes: ((e) => {
            if (e.trigger === 'zoom') { this.zoomed = true }
          })
        }
      },
      yAxis: {
        gridLineColor: '#EEF1F8',
        title: {
          text: null
        },
        allowDecimals: false
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            enabled: series[0].data.length < 2 ? true : false
          }
        }
      },
      series: series
    };
  }

  createColumnChart(categories, series) {
    this.columnChartOptions = {
      chart: {
        type: 'column',
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: 'transparent'
      },
      colors: this.colors,
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{series.name}</div><table>',
        pointFormat: '<tr><td><i class="fas fa-square mr-2" style="color:{point.color}"></i>{point.category}: {point.y}</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 0,
        style: { color: '#FFFFFF' },
        shared: true
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        categories: categories,
        gridLineColor: '#EEF1F8'
      },
      yAxis: {
        gridLineColor: '#EEF1F8',
        title: {
          text: null
        },
        allowDecimals: false
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            enabled: false
          }
        }
      },
      series: series
    };

  }

  createDifferenceChart(categories, series, average) {
    this.differenceChartOptions = {
      chart: {
        type: 'column',
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: 'transparent'
      },
      colors: ['#111B54'],
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{series.name}</div><table>',
        pointFormat: '<tr><td><i class="fas fa-square mr-2" style="color:{point.color}"></i>{point.category}: {point.y}</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 0,
        style: { color: '#FFFFFF' },
        shared: true
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        categories: categories,
        gridLineColor: '#EEF1F8'
      },
      yAxis: {
        gridLineColor: '#EEF1F8',
        title: {
          text: 'Time difference in days'
        },
        allowDecimals: false,
        plotLines: [{
          color: '#00A878',
          value: average,
          width: '2',
          zIndex: 5
        }]
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            enabled: false
          }
        }
      },
      series: series
    };

  }

  createPieChart(series) {
    this.pieChartOptions = {
      chart: {
        type: 'pie',
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: 'transparent'
      },
      colors: this.colors,
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{series.name}</div><table>',
        pointFormat: '<tr><td><i class="fas fa-square mr-2" style="color:{point.color}"></i>{point.name}: {point.y}%</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 0,
        style: { color: '#FFFFFF' }
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            enabled: false
          }
        },
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          }
        }
      },
      series: series
    };

  }

  // UPDATE

  updateFilter(filterId, filterType, filterValue) {

    // Define filter
    let filterParams = this.selectedFiltersParams.find(rf => rf.filterId === +filterId && rf.filterType === filterType);

    // If dataItemId and filterType does not exist, push to selectedFiltersParams array
    // Else dataItemId and filterType already exist, replace with new value
    if (filterParams === undefined) {
      let params = { filterType: filterType, filterId: filterId, filterValue: filterValue };
      this.selectedFiltersParams.push(params);
    } else {
      filterParams.filterValue = filterValue === '' ? null : filterValue;
    }

    // If value is null, remove from array (i.e. filter out all nulls)
    this.selectedFiltersParams = this.selectedFiltersParams.filter(sfp => sfp.filterValue !== null);
    // Save to sessionStorage
    sessionStorage.setItem('mhlFilters', JSON.stringify({ selectedFilters: this.selectedFilters, selectedFiltersParams: this.selectedFiltersParams }));

    // Add params to selected filter to return in form
    if (filterId !== this.dateFilterId) {
      let params = this.selectedFiltersParams.filter(sfp => sfp.filterId === +filterId);
      let filter = this.selectedFilters.find(sf => sf.dataItemId === +filterId);
      filter.params = {};
      params.forEach(p => { filter.params[p.filterType] = p.filterValue });
    }

  }

  updateTimeSeries(category, timeIntervalType) {
    if (typeof category === 'string' || category instanceof String) {
      category = category;
    } else {
      category = category.point.category;
    }
    if (category && timeIntervalType === 'month') {

      this.loadingReport = true;
      this.timeIntervalType = 'day';

      // Define filters
      let dateFrom = moment(category, 'MMM-YYYY').format('YYYY-MM-DD');
      let dateTo = moment(category, 'MMM-YYYY').endOf('month').format('YYYY-MM-DD');

      this.updateFilter(this.dateFilterId, 'dateFrom', dateFrom);
      this.updateFilter(this.dateFilterId, 'dateTo', dateTo);

      // Get updated data
      this.getData(this.reportDetails.reportId, this.organisation.organisationId, this.selectedFiltersParams);
    }

    // TODO: Need formatData to handle hourly data
    if (category && timeIntervalType === 'day') {
      this.loadingCaseRefs = true;

      let dateFrom = moment(category, 'DD-MMM-YY').format('YYYY-MM-DD');
      let dateTo = moment(category, 'DD-MMM-YY').add(1, 'day').format('YYYY-MM-DD');
      this.updateFilter(this.dateFilterId, 'dateFrom', dateFrom);
      this.updateFilter(this.dateFilterId, 'dateTo', dateTo);

      this.data.getCaseData(this.reportDetails.reportId, this.organisation.organisationId, this.selectedFiltersParams).subscribe(
        r => {
          this.caseDataDate = dateFrom;
          this.caseData = r.data.caseData;
        },
        e => {
          console.log(e);
          this.reportError = 'Unable to retrieve case codes. Please try refreshing the page and trying again.'
        },
        () => {
          this.loadingCaseRefs = false;
        }
      )

    }

  }

  updateOrganisation(organisationId) {
    this.organisationMenu = false;
    this.organisation = this.organisations.find(o => o.organisationId == organisationId);
    localStorage.setItem('mhlOrganisation', JSON.stringify(this.organisation));
    if (this.reportDetails) {
      this.getDetails(this.reportDetails.reportId, this.organisation.organisationId);
    }
  }

  // FORMATS

  formatTiers(tiers) {
    let outputData = [];

    tiers.forEach(t1 => {
      if (t1.childTiers) {
        t1.childTiers.forEach(t2 => {
          outputData.push({
            t1_tierId: t1.tierId,
            t1_tierName: t1.tierName,
            t2_tierId: t2.tierId,
            t2_tierName: t2.tierName,
            t2_isVisible: t2.isVisible,
            reportId: t2.reportId,
          });
          if (t2.childTiers) {
            t2.childTiers.forEach(t3 => {
              outputData.push({
                t1_tierId: t1.tierId,
                t1_tierName: t1.tierName,
                t2_tierId: t2.tierId,
                t2_tierName: t2.tierName,
                t2_isVisible: t2.isVisible,
                t3_tierId: t3.tierId,
                t3_tierName: t3.tierName,
                t3_isVisible: t3.isVisible,
                reportId: t3.reportId
              });
              if (t3.childTiers) {
                t3.childTiers.forEach(t4 => {
                  outputData.push({
                    t1_tierId: t1.tierId,
                    t1_tierName: t1.tierName,
                    t2_tierId: t2.tierId,
                    t2_tierName: t2.tierName,
                    t2_isVisible: t2.isVisible,
                    t3_tierId: t3.tierId,
                    t3_tierName: t3.tierName,
                    t3_isVisible: t3.isVisible,
                    t4_tierId: t4.tierId,
                    t4_tierName: t4.tierName,
                    t4_isVisible: t4.isVisible,
                    reportId: t4.reportId
                  });
                })
              }
            })
          }
        })
      }
    });

    // Create tier map
    outputData.forEach(o => {
      o.tierIdMap = [
        o.t1_tierId, 
        o.t2_tierId ? o.t2_tierId : null,  
        o.t3_tierId ? o.t3_tierId : null, 
        o.t4_tierId ? o.t4_tierId : null
      ];
      o.tierNameMap = [
        o.t1_tierName, 
        o.t2_tierName ? o.t2_tierName : null,  
        o.t3_tierName ? o.t3_tierName : null, 
        o.t4_tierName ? o.t4_tierName : null
      ];
    });

    outputData.forEach(o => {
      o.tierIdMap = o.tierIdMap.filter(t => t !== null);
      o.tierNameMap = o.tierNameMap.filter(t => t !== null);
    });

    // Remove null reports
    outputData = outputData.filter(t => t.reportId !== null);

    return outputData;
  }

  formatData(reportData, seriesName, startDate, endDate, timeIntervalType) {

    let dateStart = moment(startDate),
        dateEnd = moment(endDate),
        interim = dateStart.clone(),
        dateFormat, interimFormat,
        seriesData = [];

    switch (timeIntervalType) {
      case 'month':
        dateFormat = 'M';
        interimFormat = 'MMM-yy';
        break;
      case 'day':
        dateFormat = 'DD';
        interimFormat = 'DD-MMM-YY';
        break;

      default:
        console.log('No time interval type selected')
    }

    while (dateEnd > interim || interim.format(dateFormat) === dateEnd.format(dateFormat)) {
      seriesData.push({ 
        seriesName: seriesName,
        date: interim.format(interimFormat),
        caseCount: 0, // TODO: Check with JG if zeros are ok here!
      });
      interim.add(1, timeIntervalType);
    }

    seriesData.forEach(p => {
      reportData.forEach(rd => {
        if (p.date === rd.date) {
          p.caseCount = rd.caseCount;
        }
        p.displaySequence = rd.displaySequence;
      })
    });
    
    return seriesData;
  }

  // UTILITIES

  checkFilters(reportFilters, savedFilters, checkAgainst) {
    let checkedFilters = [];

    if (savedFilters.length) {
      savedFilters.forEach(sf => {
        reportFilters.forEach(rf => {
          let match = rf.dataItemId === sf[checkAgainst];
          if (match) {
            checkedFilters.push(sf);
          }
        })
      })
    }

    return checkedFilters;
  }

  getDateRange(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
        dateArray.push({ date: moment(currentDate).format('YYYY-MM-DD'), count: null })
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
  }

  createLegend(data) {
    // Get previous visibilities
    let savedLegend = this.legend;
    // Set data and scrub legend
    this.lineChart = data;
    this.legend = [];
    // Set legend and visibility
    let series = data.series;
    if (series.length > 1) {
      series.forEach(s => {
        let savedMatch = savedLegend ? savedLegend.find(l => l.name === s.name) : null;
        this.legend.push({
          name: s.name,
          color: s.color,
          visibility: savedMatch ? savedMatch.visibility : true
        })
      })
    }
    // Update line chart with visibilities
    this.legend.forEach((l, i) => {
      this.lineChart['series'][i].update({ visible: l.visibility });
    });
  }

  toggleSeriesVisibility(index) {
    let visibility = this.lineChart['series'][index].visible;
    if (visibility === true) {
      this.legend[index].visibility = false;
      this.lineChart['series'][index].update({ visible: false });
    } else {
      this.legend[index].visibility = true;
      this.lineChart['series'][index].update({ visible: true });
    }
  }

  toggleAllSeries() {
    let newVisibility = this.legend[0].visibility ? false : true;
    this.legend.forEach((l, i) => {
      l.visibility = newVisibility;
      this.lineChart['series'][i].update({ visible: newVisibility });
    });
  }

  clearFilters() {
    // Retrieve saved reportFilters
    let reportFilters = JSON.parse(this.report);
    // Reset selectedFilters and reportFilters
    this.selectedFilters = [];
    this.selectedFiltersParams = this.selectedFiltersParams.filter(sfp => sfp.filterId === 1);
    // Save to sessionStorage
    sessionStorage.setItem('mhlFilters', JSON.stringify({ selectedFilters: this.selectedFilters, selectedFiltersParams: this.selectedFiltersParams }));
    // Update report filters
    this.reportFilters = reportFilters.reportFilters;
    // Close filters
    this.setMenu('any');
    this.getData(this.reportDetails.reportId, this.organisation.organisationId, this.selectedFiltersParams);
  }

  removeFilter(dataItemId) {
    // Define filter
    let filter = this.selectedFilters.find(rf => rf.dataItemId === +dataItemId);
    // Add it back to reportFilters
    this.reportFilters.push(filter);
    // Order report filters by name
    this.reportFilters = this.order.transform(this.reportFilters, 'filterName');
    // Remove from selectedFilters
    this.selectedFilters.splice(this.selectedFilters.indexOf(filter), 1);
    // Remove from selectedFiltersParams
    this.selectedFiltersParams = this.selectedFiltersParams.filter(sfp => sfp.filterId !== +dataItemId );
    // Save to sessionStorage
    sessionStorage.setItem('mhlFilters', JSON.stringify({ selectedFilters: this.selectedFilters, selectedFiltersParams: this.selectedFiltersParams }));
  }

  reset() {
    this.timeIntervalType = 'month';
    this.loadingReport = true;

    // Define filters
    let dateFrom = moment(this.reportDetails.minMaxDates.minDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
    let dateTo = moment(this.reportDetails.minMaxDates.maxDate, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD');

    this.updateFilter(this.dateFilterId, 'dateFrom', dateFrom);
    this.updateFilter(this.dateFilterId, 'dateTo', dateTo);

    // Clear filters
    this.clearFilters();
  }

}
