<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-4 col-sm-6 col-xs-10 col-12">
      <div id="login" class="card border-dark">

        <!-- Login -->
        <div class="card-body" *ngIf="!auth.authenticated()">
          <img src="/assets/logo_beta.png" class="w-50 mb-3" alt="Mountain Healthcare Logo">
          <div class="alert alert-danger mt-2" *ngIf="devMode">
            <strong>Development Site:</strong> This site is now being used for the development of new projects. <a href="https://www.mhlbenchmarking.co.uk" class="link">Please click here to login to the live Mountain Healthcare Reporting Toolkit.</a> 
          </div>
          <form [formGroup]="loginForm" id="loginForm">
            <div class="form-group">
              <label for="username">Username</label>
              <input formControlName="username" type="text" class="form-control">
            </div>
            <div class="form-group">
              <label for="username">Password</label>
              <input formControlName="password" type="password" class="form-control">
            </div>
            <button class="btn btn-primary" (click)="login()" [disabled]="loginLoading">
              <span *ngIf="loginLoading" >Logging in...</span>
              <span *ngIf="!loginLoading" >Login</span>
            </button>
          </form>
          <div *ngIf="error" class="error">
            {{ error }}
          </div>
        </div>

        <div class="card-body" *ngIf="auth.authenticated() && userProjects">
          <h4>Select Project</h4>
          <p>You have access to multiple projects. Please select the project you would like to view from the list below.</p>
          <div *ngFor="let project of userProjects">
            <div class="project" (click)="navigate(project.projectId)">
              {{ project.projectName }}
            </div>
          </div>
        </div>
      </div>
  
      <div class="w-100 text-secondary mt-1">
        <small>
          &copy; Mountain Healthcare Limited {{ currentYear }}
        </small>
      </div>
  
    </div>
  </div>
</div>