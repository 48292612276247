import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class DataService {
  private URL: string = environment.apiURL;

  constructor(
    private http: HttpClient
  ) { }

  public getOrganisations(projectId: number): Observable<any> {
    let token = localStorage.getItem('mhlUserToken');

    return this.http.get(
      this.URL + 'user/organisations', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('projectId', projectId.toString())
      }
    );
  }

  public getOrganisationTiers(projectId: number): Observable<any> {
    let token = localStorage.getItem('mhlUserToken');

    return this.http.get(
      this.URL + 'user/organisationHierarchy', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('projectId', projectId.toString())
      }
    );
  }

  public getTiers(projectId: number, dashboardView?: boolean): Observable<any> {
    let token = localStorage.getItem('mhlUserToken');

    let params: HttpParams = new HttpParams().set('projectId', projectId.toString());

    if (dashboardView) {
      params = params.append('dashboardView', 'Y');
    }

    return this.http.get(
      this.URL + 'tier', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public getDetails(reportId: number, organisationId?: number): Observable<any> {
    let token = localStorage.getItem('mhlUserToken');
    let params: HttpParams = new HttpParams();

    if (organisationId) {
      params = params.set('organisationId', organisationId.toString());
    }

    return this.http.get(
      this.URL + 'report/details/' + reportId, {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public getData(reportId: number, organisationId: number, filters?): Observable<any> {
    let token = localStorage.getItem('mhlUserToken');
    let params: HttpParams = new HttpParams();

    params = params.set('organisationId', organisationId.toString());

    if (filters) {
      filters.forEach(f => {
        params = params.append('filterType', f.filterType.toString()).append('filterId', f.filterId.toString()).append('filterValue', f.filterValue.toString())
      });
    }

    return this.http.get(
      this.URL + 'report/organisationData/' + reportId, {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public getNationalData(reportId: number, organisationId: number, period, filters): Observable<any> {
    let token = localStorage.getItem('mhlUserToken');
    let params: HttpParams = new HttpParams();
    
    params = params.set('organisationId', organisationId.toString());

    if (period) {
      period.forEach(f => {
        params = params.append('filterType', f.filterType.toString()).append('filterId', f.filterId.toString()).append('filterValue', f.filterValue.toString())
      });
    }

    if (filters) {
      filters.forEach(f => {
        params = params.append('filterType', f.filterType.toString()).append('filterId', f.filterId.toString()).append('filterValue', f.filterValue.toString())
      });
    }

    return this.http.get(
      this.URL + 'report/nationalData/' + reportId, {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public getTimeSeriesData(reportId: number, organisationId: number, timeInterval: number, timeIntervalType: string, filters?): Observable<any> {
    let token = localStorage.getItem('mhlUserToken');
    let params: HttpParams = new HttpParams();

    params = params.set('organisationId', organisationId.toString()).set('timeInterval', timeInterval.toString()).set('timeIntervalType', timeIntervalType.toString());

    if (filters) {
      filters.forEach(f => {
        params = params.append('filterType', f.filterType.toString()).append('filterId', f.filterId.toString()).append('filterValue', f.filterValue.toString())
      });
    }

    return this.http.get(
      this.URL + 'report/organisationTimeseries/' + reportId, {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public getNationalTimeSeriesData(reportId: number, organisationId: number, timeInterval: number, timeIntervalType: string, filters?): Observable<any> {
    let token = localStorage.getItem('mhlUserToken');
    let params: HttpParams = new HttpParams();

    params = params.set('organisationId', organisationId.toString()).set('timeInterval', timeInterval.toString()).set('timeIntervalType', timeIntervalType.toString());

    if (filters) {
      filters.forEach(f => {
        params = params.append('filterType', f.filterType.toString()).append('filterId', f.filterId.toString()).append('filterValue', f.filterValue.toString())
      });
    }

    return this.http.get(
      this.URL + 'report/nationalTimeseries/' + reportId, {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public getNationalSplitData(selectedSeriesId: number, organisationId: number, period, filters?): Observable<any> {
    let token = localStorage.getItem('mhlUserToken');
    let params: HttpParams = new HttpParams();

    params = params.set('organisationId', organisationId.toString()).set('selectedSeriesId', selectedSeriesId.toString());

    if (period) {
      period.forEach(f => {
        params = params.append('filterType', f.filterType.toString()).append('filterId', f.filterId.toString()).append('filterValue', f.filterValue.toString())
      });
    }

    if (filters) {
      filters.forEach(f => {
        params = params.append('filterType', f.filterType.toString()).append('filterId', f.filterId.toString()).append('filterValue', f.filterValue.toString())
      });
    }

    return this.http.get(
      this.URL + 'report/nationalPercentageSplitData', {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

  public getCaseData(reportId: number, organisationId: number, filters): Observable<any> {
    let token = localStorage.getItem('mhlUserToken');
    let params: HttpParams = new HttpParams();

    params = params.set('organisationId', organisationId.toString());

    if (filters) {
      filters.forEach(f => {
        params = params.append('filterType', f.filterType.toString()).append('filterId', f.filterId.toString()).append('filterValue', f.filterValue.toString())
      });
    }

    return this.http.get(
      this.URL + 'report/organisationCaseData/' + reportId, {
        headers: new HttpHeaders().set('Authorization', token),
        params: params
      }
    );
  }

}