import { Component, ViewEncapsulation } from '@angular/core';
import { UserService } from './_services/user.service';
import { ActivationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'Mountain Healthcare Reporting Tool';
  component;
  
  constructor(
    public user: UserService,
    private router: Router
  ) {
    // Current component
    router.events.subscribe((val) => {
      if (val instanceof ActivationEnd) {
          this.component = val.snapshot.component['name'];
      }
    });
  }

  logout() {
    localStorage.removeItem('mhlUserToken');
    localStorage.removeItem('mhlOrganisation');
    this.router.navigate(['login']);
  }

  linkToFeedbackForm() {
    let airtableFormUrl = 'https://airtable.com/shrIaeQ0XagUhGBOz?prefill_Source%20URL=';
    let currentUrl = 'https://www.mhlbenchmarking.co.uk' + this.router.url;
    window.open(airtableFormUrl + encodeURIComponent(currentUrl), '_blank');
  }

}
