import { Component, OnInit } from '@angular/core';

// Services
import { DataService } from '../_services/data.service';

// Packages
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { GroupByPipe } from 'ngx-pipes';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  providers: [ GroupByPipe ]
})
export class WelcomeComponent implements OnInit {

  // Loading
  projectId: number = 1;
  reportLoading; reportError;
  permissionsError: boolean = false;
  loadingCaseRefs: boolean = false;

  // Organisations
  organisationMenu;
  organisations;
  organisationTiers;

  // Dates
  startDate; endDate;
  dateFilterId;
  timeIntervalType: string = 'month';

  // Params
  reportId: number;
  organisation;
  selectedFiltersParams = [];

  // Data
  tiers; tier;
  reportData; reportStats;
  caseData; caseDataDate;
  colors: any = [
    "#e03616","#f1a208","#00a878","#111b54","#a01a7d","#09c2de",
    "#f39886","#fbd281","#54ffcf","#566adc","#e873c9","#78e8f9",
    "#6f1c0b","#795204","#00543c","#090e2a","#4f0d3d","#05606e"            
  ]; 

  // Charts
  Highcharts: typeof Highcharts = Highcharts;
  timeSeriesChartOptions: Highcharts.Options; timeSeriesChart: any;

  constructor(
    private data: DataService,
    public group: GroupByPipe,
  ) { }

  ngOnInit() {
    this.getOrganisations(this.projectId);
  }

  getOrganisations(projectId) {
    this.data.getOrganisations(projectId).subscribe(
      r => { 

        // Get and set organisation
        this.organisations = r.data.organisationList;

        //Check for saved organisation
        let savedOrganisation = localStorage.getItem('mhlOrganisation');
        if (savedOrganisation && savedOrganisation !== 'undefined') {
          this.organisation = this.organisations.find(o => o.organisationId == JSON.parse(savedOrganisation).organisationId);
        } else {
          this.organisation = this.organisations[0];
        }

        // Save to localStorage
        localStorage.setItem('mhlOrganisation', JSON.stringify(this.organisation));

        // Get dashboard data if organisation set
        if (this.organisation) {
          this.getDashboard(projectId);
        } else {
          this.permissionsError = true;
        }
        
      },
      e => { 
        console.log(e)
      }
    )
  }

  getOrganisationTiers(projectId) {
    if (!this.organisationTiers) {
      this.data.getOrganisationTiers(projectId).subscribe(
        r => {
          this.organisationTiers = r.data.organisationHierarchy;
        },
        e => {
          console.log(e);
        }
      )
    }
  }

  getDashboard(projectId) {
    this.data.getTiers(projectId, true).subscribe(
      r => {
        this.tiers = r.data.tierList;
        this.tier = this.tiers[0];
        this.getDetails(this.tier.reportId);
      },
      e => {
        console.log(e);
      }
    )
  }

  getDetails(reportId) {
    this.reportError = null;
    this.data.getDetails(reportId).subscribe(
      r => {

        // Set correct filterId for dates
        let filterId = r.data.reportDetails.reportParameters.find(rp => rp.parameterType === 'T');
        if (filterId) {
          this.dateFilterId = filterId.dataItemId;
        } else {
          this.dateFilterId = null;
        }

        this.getTimeSeriesData(this.tier.reportId, this.organisation.organisationId, this.timeIntervalType, this.selectedFiltersParams);
      },
      e => {
        console.log(e);
        this.reportError = 'There has been an error loading the report information. Please try refreshing the page.'
      }
    )
  }

  getTimeSeriesData(reportId, organisationId, timeIntervalType, filters?) {
    this.reportLoading = true;
    this.timeIntervalType = timeIntervalType;
    
    this.data.getTimeSeriesData(reportId, organisationId, 1, timeIntervalType, filters).subscribe(
      r => { 
        let reportData = r.data.reportTimeseries;

        // Set date range
        let dates = reportData.map(d => moment(d.datePeriod));       
        this.startDate = moment.min(dates).format('YYYY-MM-DD');
        this.endDate = moment.max(dates).format('YYYY-MM-DD');
        
        // Format dates
        if (reportData.length) {
          switch (timeIntervalType) {
            case 'month':
              reportData.forEach(rd => { rd.date = moment(rd.datePeriod).format('MMM-yy') });
              break;
            case 'day':
              reportData.forEach(rd => { rd.date = moment(rd.datePeriod).format('DD-MMM-YY') });
              break;
            case 'hour':
              reportData.forEach(rd => { rd.date = moment(rd.datePeriod).format('HH:mm') });
              break;
            default:
              console.log('No time interval type selected')
          }
        } else {
          return;
        }

        // Group by series
        reportData = this.group.transform(reportData, 'seriesName');

        // Add missing dates
        Object.keys(reportData).forEach(rd => {
          reportData[rd] = this.formatData(reportData[rd], rd, this.startDate, this.endDate, timeIntervalType);
        })

        // Define categories
        let categories = reportData[Object.keys(reportData)[0]].map(rd => rd.date);
        
        // Define data
        let data = [];
        Object.keys(reportData).forEach(rd => {
          data.push({
            name: rd,
            data: reportData[rd].map(d => d.caseCount)
          })
        })

        // Create chart
        this.createTimeSeriesChart(categories, data);

        // Set stats and data if monthly
        if (this.timeIntervalType === 'month') {
          this.formatStats(reportData);
        }

        // Complete load
        this.reportLoading = false;

      },
      e => {
        console.log(e);
      }
    )

  }

  // CHARTS

  createTimeSeriesChart(categories, series) {
    this.timeSeriesChartOptions = {
      chart: {
        type: 'line',
        //zoomType: 'x', 
        animation: false,
        style: { 
          fontFamily: '"Helvetica Neue", Arial, sans-serif',
          cursor: 'pointer'
        },
        backgroundColor: 'transparent',
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        },
        events: {
          load: ((e) => { 
            this.timeSeriesChart = e.target;
          }),
          click: ((e) => {
            let categoryIndex = Math.ceil(e.xAxis[0].value);
            let categoryName = this.timeSeriesChart.axes[0].categories[categoryIndex];
            this.updateTimeSeries({ point: { category: categoryName } }, this.timeIntervalType);
          })
        },
      },
      colors: this.colors,
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{point.x}</div><table>',
        pointFormat: '<tr><td style="padding:0.25em 0;"><i class="fas fa-square mr-2" style="color:{series.color}"></i>{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 2,
        style: { color: '#FFFFFF' },
        shared: true
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        categories: categories,
        gridLineColor: '#EEF1F8',
        crosshair: true
      },
      yAxis: {
        gridLineColor: '#EEF1F8',
        title: {
          text: null
        },
        allowDecimals: false
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            enabled: false
          }
        }
      },
      series: series
    };
  }

  // UPDATES

  updateFilter(filterId, filterType, filterValue) {

    // Define filter
    let filterParams = this.selectedFiltersParams.find(rf => rf.filterId === +filterId && rf.filterType === filterType);

    // If dataItemId and filterType does not exist, push to selectedFiltersParams array
    // Else dataItemId and filterType already exist, replace with new value
    if (filterParams === undefined) {
      let params = { filterType: filterType, filterId: filterId, filterValue: filterValue };
      this.selectedFiltersParams.push(params);
    } else {
      filterParams.filterValue = filterValue === '' ? null : filterValue;
    }

    // If value is null, remove from array (i.e. filter out all nulls)
    this.selectedFiltersParams = this.selectedFiltersParams.filter(sfp => sfp.filterValue !== null);

  }

  updateTimeSeries(event, timeIntervalType) {

    let correctedDate;

    // MONTH CLICK: Get daily data for month
    if (this.dateFilterId && event.point && timeIntervalType === 'month') {

      this.timeIntervalType = 'day';

      // Correction of date to overcome Firefox invalid date issue
      let parsedDate = event.point.category.split('-');
      let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      let month = months.findIndex(m => m === parsedDate[0]);
      correctedDate = new Date(+parsedDate[1], month, 1);
    
      let dateFrom = moment(correctedDate).format('YYYY-MM-DD');
      let dateTo = moment(correctedDate).endOf('month').format('YYYY-MM-DD');

      this.updateFilter(this.dateFilterId, 'dateFrom', dateFrom);
      this.updateFilter(this.dateFilterId, 'dateTo', dateTo);

      this.getTimeSeriesData(this.tier.reportId, this.organisation.organisationId, this.timeIntervalType, this.selectedFiltersParams);
    }

    // DAY CLICK: Get case references
    if (event.point && timeIntervalType === 'day') {
      this.loadingCaseRefs = true;

      let dateFrom = moment(correctedDate).format('YYYY-MM-DD');
      this.updateFilter(this.dateFilterId, 'dateFrom', dateFrom);
      this.updateFilter(this.dateFilterId, 'dateTo', dateFrom);

      this.data.getCaseData(this.tier.reportId, this.organisation.organisationId, this.selectedFiltersParams).subscribe(
        r => {
          this.caseDataDate = dateFrom;
          this.caseData = r.data.caseData;
        },
        e => {
          console.log(e);
          this.reportError = 'Unable to retrieve case codes. Please try refreshing the page and trying again.'
        },
        () => {
          this.loadingCaseRefs = false;
        }
      )
    }
    
  }

  updateOrganisation(organisationId) {
    this.timeIntervalType = 'month';
    this.selectedFiltersParams = [];
    this.caseData = null;
    this.organisationMenu = false;
    this.organisation = this.organisations.find(o => o.organisationId == organisationId);
    localStorage.setItem('mhlOrganisation', JSON.stringify(this.organisation));
    this.getTimeSeriesData(this.tier.reportId, organisationId, this.timeIntervalType, this.selectedFiltersParams);
  }

  updateTier(tierId) {
    this.timeIntervalType = 'month';
    this.selectedFiltersParams = [];
    this.caseData = null;
    this.tier = this.tiers.find(t => t.tierId == tierId);
    this.getDetails(this.tier.reportId);
  }

  // UTILITIES

  formatData(reportData, seriesName, startDate, endDate, timeIntervalType) {

    let dateStart = moment(startDate),
        dateEnd = moment(endDate),
        interim = dateStart.clone(),
        dateFormat, interimFormat,
        seriesData = [];

    switch (timeIntervalType) {
      case 'month':
        dateFormat = 'M';
        interimFormat = 'MMM-yy';
        break;
      case 'day':
        dateFormat = 'DD';
        interimFormat = 'DD-MMM-YY';
        break;

      default:
        console.log('No time interval type selected')
    }

    while (dateEnd > interim || interim.format(dateFormat) === dateEnd.format(dateFormat)) {
      seriesData.push({ 
        seriesName: seriesName,
        date: interim.format(interimFormat),
        caseCount: 0 // TODO: Check with JG if zeros are ok here!
      });
      interim.add(1, timeIntervalType);
    }

    seriesData.forEach(p => {
      reportData.forEach(rd => {
        if (p.date === rd.date) {
          p.caseCount = rd.caseCount;
        }
      })
    });
    
    return seriesData;
  }

  formatStats(reportData) {

    // Save report data
    this.reportData = reportData;

    // Latest month
    let latestMonth = moment(this.endDate).startOf('month').format('MMM-YYYY');
    let latestMonthData = reportData[Object.keys(reportData)[0]].find(rd => rd.date === latestMonth);
    
    // Previous month
    let previousMonth = moment(this.endDate).subtract(1, 'month').format('MMM-YYYY');
    let previousMonthData = reportData[Object.keys(reportData)[0]].find(rd => rd.date === previousMonth);

    // Previous year
    let previousYear = moment(this.endDate).subtract(1, 'year').format('MMM-YYYY');
    let previousYearData = reportData[Object.keys(reportData)[0]].find(rd => rd.date === previousYear);

    // Save stats
    this.reportStats = {
      latestMonth: latestMonth,
      latestMonthCaseCount: latestMonthData.caseCount,
      previousMonth: previousMonth,
      previousMonthCaseCount: previousMonthData && previousMonthData.caseCount ? previousMonthData.caseCount : null,
      previousYear: previousYear,
      previousYearCaseCount: previousYearData && previousYearData.caseCount ? previousYearData.caseCount : null
    }

    // Add monthly difference
    this.reportStats.monthDiff = getPercentageChange(this.reportStats.previousMonthCaseCount, this.reportStats.latestMonthCaseCount).diff;
    this.reportStats.monthStatus = getPercentageChange(this.reportStats.previousMonthCaseCount, this.reportStats.latestMonthCaseCount).status;

    // Add yearly difference
    this.reportStats.yearDiff = getPercentageChange(this.reportStats.previousYearCaseCount, this.reportStats.latestMonthCaseCount).diff;
    this.reportStats.yearStatus = getPercentageChange(this.reportStats.previousYearCaseCount, this.reportStats.latestMonthCaseCount).status;

    // Percentage change function
    function getPercentageChange(oldNumber, newNumber){
      var decreaseValue = oldNumber - newNumber;
      return {
        diff: Math.abs((decreaseValue / oldNumber) * 100),
        status: decreaseValue < 0 ? 'increase' : 'decrease'
      }
    }
  }

  reset() {
    this.timeIntervalType = 'month';
    this.selectedFiltersParams = [];
    this.caseData = null;
    this.getDetails(this.tier.reportId);
  }

}
