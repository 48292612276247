import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserService {
  private URL: string = environment.apiURL;

  constructor(
      private http: HttpClient,
      public jwtHelper: JwtHelperService
    ) { }

  public getToken(username, password): Observable<any> {
    return this.http.get(this.URL + 'authentication', {
        headers: new HttpHeaders().append('Authorization', 'Basic ' + btoa(username + ':' + password))
    })
  }

  public isAuthenticated() {
    return !this.jwtHelper.isTokenExpired();
  }

}