<!-- ERRORS -->
<div *ngIf="permissionsError">
  <div class="container-fluid mt-5">
      <div class="row justify-content-center">
          <div class="col-sm-8">
              <div class="alert alert-danger text-center">
                  You do not currently have the necessary permissions to view the dashboard and toolkit. Please contact support.
              </div>
          </div>
      </div>
  </div>
</div>

<!-- MENU CLOSE -->
<div id="menuBackground" *ngIf="selectedMenu.any" (click)="setMenu('any')"></div>

<div *ngIf="organisation && !permissionsError">

  <div class="bg-primary p-2" *ngIf="organisations && organisations.length > 1">
    <div class="container-fluid">
      <div class="row justify-content-end">

        <!-- Organisation Tiers -->
        <div class="col-lg-4 col-md-6 col-sm-10 col-12" *ngIf="organisations && organisations.length > 1">

          <div class="position-relative">
              <button class="btn btn-outline-secondary btn-block text-left" (click)="getOrganisationTiers(1); organisationMenu = !organisationMenu">
                  {{ organisation ? organisation.organisationName : 'Select organisation...'}}
                  <i [class]="organisationMenu ? 'fas fa-angle-up ml-2' : 'fas fa-angle-down ml-2'"></i>
              </button>
              <div id="organisationMenu" *ngIf="organisationMenu" class="position-absolute mt-3 p-3 bg-white rounded border border-secondary shadow">

                  <div *ngFor="let o1 of organisationTiers" class="mb-2">
                      <!-- Tier 1 -->
                      <div (click)="o1.open = !o1.open" class="org-tier">
                          <div class="row">
                              <div class="col-1 text-center">
                                  <ng-container *ngIf="o1.children.length > 0">
                                      <i [class]="o1.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                  </ng-container>
                                  <ng-container *ngIf="o1.children.length == 0">
                                      <i class="fas fa-caret-down fa-fw" style="color:transparent"></i>
                                  </ng-container>
                              </div>
                              <div [class]="o1.organisationId === organisation.organisationId ? 'col-10 selected' : 'col-10'">
                                  <span [class]="o1.userHasRole ? 'link' : 'text-muted'">
                                      {{ o1.organisationName }}&nbsp;
                                      <i *ngIf="o1.userHasRole" class="org-tier-select" (click)="updateOrganisation(o1.organisationId)">Show&nbsp;Data</i>
                                  </span>
                              </div>
                          </div>
                      </div>
                      <div *ngIf="o1.open">
                          <!-- Tier 2 -->
                          <div *ngFor="let o2 of o1.children" class="ml-3">
                              <div (click)="o2.open = !o2.open" class="org-tier">
                                  <div class="row">
                                      <div class="col-1 text-center">
                                          <ng-container *ngIf="o2.children.length > 0">
                                              <i [class]="o2.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                          </ng-container>
                                          <ng-container *ngIf="o2.children.length == 0">
                                              <i class="fas fa-caret-down fa-fw" style="color:transparent"></i>
                                          </ng-container>
                                      </div>
                                      <div [class]="o2.organisationId === organisation.organisationId ? 'col-10 selected' : 'col-10'">
                                          <span [class]="o2.userHasRole ? 'link' : 'text-muted'">
                                              {{ o2.organisationName }}&nbsp;
                                              <i *ngIf="o2.userHasRole" class="org-tier-select" (click)="updateOrganisation(o2.organisationId)">Show&nbsp;Data</i>
                                          </span>
                                      </div>
                                  </div>
                              </div>
                              <div *ngIf="o2.open">
                                  <!-- Tier 3 -->
                                  <div *ngFor="let o3 of o2.children" class="ml-3">
                                      <div (click)="o3.open = !o3.open" class="org-tier">
                                          <div class="row">
                                              <div class="col-1 text-center">
                                                  <ng-container *ngIf="o3.children.length > 0">
                                                      <i [class]="o3.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                                  </ng-container>
                                                  <ng-container *ngIf="o3.children.length == 0">
                                                      <i class="fas fa-caret-down fa-fw" style="color:transparent"></i>
                                                  </ng-container>
                                              </div>
                                              <div [class]="o3.organisationId === organisation.organisationId ? 'col-10 selected' : 'col-10'">
                                                  <span [class]="o3.userHasRole ? 'link' : 'text-muted'">
                                                      {{ o3.organisationName }}&nbsp;
                                                      <i *ngIf="o3.userHasRole" class="org-tier-select" (click)="updateOrganisation(o3.organisationId)">Show&nbsp;Data</i>
                                                  </span>
                                              </div>
                                          </div>
                                      </div>
                                      <div *ngIf="o3.open">
                                          <!-- Tier 4 -->
                                          <div *ngFor="let o4 of o3.children" class="ml-3">
                                              <div (click)="o4.open = !o4.open" class="org-tier">
                                                  <div class="row">
                                                      <div class="col-1 text-center">
                                                          <ng-container *ngIf="o4.children.length > 0">
                                                              <i [class]="o4.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                                          </ng-container>
                                                          <ng-container *ngIf="o4.children.length == 0">
                                                              <i class="fas fa-caret-down fa-fw" style="color:transparent"></i>
                                                          </ng-container>
                                                      </div>
                                                      <div [class]="o4.organisationId === organisation.organisationId ? 'col-10 selected' : 'col-10'">
                                                          <span [class]="o4.userHasRole ? 'link' : 'text-muted'">
                                                              {{ o4.organisationName }}&nbsp;
                                                              <i *ngIf="o4.userHasRole" class="org-tier-select" (click)="updateOrganisation(o4.organisationId)">Show&nbsp;Data</i>
                                                          </span>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div *ngIf="o4.open">
                                                <!-- Tier 5 -->
                                                <div *ngFor="let o5 of o4.children" class="ml-3">
                                                    <div (click)="o5.open = !o5.open" class="org-tier">
                                                        <div class="row">
                                                            <div class="col-1 text-center">
                                                                <ng-container *ngIf="o5.children.length > 0">
                                                                    <i [class]="o5.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                                                </ng-container>
                                                                <ng-container *ngIf="o5.children.length == 0">
                                                                    <i class="fas fa-caret-down fa-fw" style="color:transparent"></i>
                                                                </ng-container>
                                                            </div>
                                                            <div [class]="o5.organisationId === organisation.organisationId ? 'col-10 selected' : 'col-10'">
                                                                <span [class]="o5.userHasRole ? 'link' : 'text-muted'">
                                                                    {{ o5.organisationName }}&nbsp;
                                                                    <i *ngIf="o5.userHasRole" class="org-tier-select" (click)="updateOrganisation(o5.organisationId)">Show&nbsp;Data</i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </div>

        </div>
        
      </div>
    </div>
  </div>

  <div class="container-fluid">

    <div class="row justify-content-center">
      <div class="col-11">

        <!-- SELECTOR -->
        <div class="row mt-4">

          <!-- TIERS -->
          <div class="col-md-4 col-sm-6 col-12">

            <!-- Searchable Tiers -->
            <div *ngIf="searchableTiers" class="form-group tiers-container">
              <input type="text" [(ngModel)]="search" placeholder="Browse or search {{ searchableTiers.length }} reports..." class="form-control border-primary text-primary" (click)="setMenu('tiers'); $event.target.select()" [value]="reportDetails ? reportDetails.reportName : null" [disabled]="loadingReport">
              <div *ngIf="searchableTiers && (searchableTiers | filterBy: ['tierNameMap', 'reportId']: search).length === 0" class="card card-tiers">
                <div class="search-result text-secondary text-center">
                  There are no results for this search
                </div>
              </div>
              <div *ngIf="search && searchableTiers && (searchableTiers | filterBy: ['tierNameMap', 'reportId']: search).length" class="card card-tiers">
                <div *ngFor="let t of searchableTiers | filterBy: ['tierNameMap', 'reportId']: search" class="search-result" (click)="getDetails(t.reportId, organisation.organisationId); search = null">
                  {{ t.tierNameMap.slice(-1) }}
                  <div [innerHTML]="t.tierNameMap.join(' &rsaquo; ')" class="text-secondary small"></div>
                </div>
              </div>
              <div *ngIf="selectedMenu.tiers && !search" class="card card-tiers">
                <!-- Tier Structure -->
                <div id="tiers" *ngIf="tiers">
                  <div class="subtier" *ngFor="let t1 of tiers">
                      <div *ngIf="t1.isVisible === 'Y'" (click)="t1.reportId !== null ? getDetails(t1.reportId, organisation.organisationId) : t1.selected = !t1.selected">
                        <ng-container *ngIf="t1.childTiers !== null">
                          <i [class]="t1.selected ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                        </ng-container>
                        <ng-container *ngIf="t1.childTiers === null">
                          <i class="fas fa-minus fa-fw" style="color:transparent"></i>
                        </ng-container>
                        <span [style.font-weight]="t1.selected && (reportDetails && t1.reportId === reportDetails.reportId) ? 'bold' : 'normal'">{{ t1.tierName }}</span>
                      </div>
                      <div *ngIf="t1.childTiers !== null && t1.selected">
                        <div class="subtier" *ngFor="let t2 of t1.childTiers">
                          <div *ngIf="t2.isVisible === 'Y'" (click)="t2.reportId !== null ? getDetails(t2.reportId, organisation.organisationId) : t2.selected = !t2.selected">
                            <ng-container *ngIf="t2.childTiers !== null">
                              <i [class]="t2.selected ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                            </ng-container>
                            <ng-container *ngIf="t2.childTiers === null">
                              <i class="fas fa-minus fa-fw" style="color:transparent"></i>
                            </ng-container>
                            <span [style.font-weight]="t2.selected && (reportDetails && t2.reportId === reportDetails.reportId) ? 'bold' : 'normal'">{{ t2.tierName }}</span>
                          </div>
                          <div *ngIf="t2.childTiers !== null && t2.selected">
                            <div class="subtier" *ngFor="let t3 of t2.childTiers">
                              <div *ngIf="t3.isVisible === 'Y'" (click)="t3.reportId !== null ? getDetails(t3.reportId, organisation.organisationId) : t3.selected = !t3.selected">
                                <ng-container *ngIf="t3.childTiers !== null">
                                  <i [class]="t3.selected ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                </ng-container>
                                <ng-container *ngIf="t3.childTiers === null">
                                  <i class="fas fa-minus fa-fw" style="color:transparent"></i>
                                </ng-container>
                                <span [style.font-weight]="t3.selected && (reportDetails && t3.reportId === reportDetails.reportId) ? 'bold' : 'normal'">{{ t3.tierName }}</span>
                              </div>
                              <div class="subtier" *ngFor="let t4 of t3.childTiers">
                                <div *ngIf="t4.isVisible === 'Y'" (click)="getDetails(t4.reportId, organisation.organisationId)">
                                  <span [style.font-weight]="t4.selected && (reportDetails && t4.reportId === reportDetails.reportId) ? 'bold' : 'normal'">{{ t4.tierName }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Welcome -->
            <div *ngIf="tiers && !reportDetails" class="ml-2">
              <i class="fas fa-arrow-up mb-2"></i>
              <p>Start by clicking in the search bar above to find a report by name, or by browsing all reports using the tier structure.</p>
            </div>

          </div>

          <!-- FILTERS -->
          <div class="col-md-8 col-sm-6 col-12" *ngIf="reportDetails">

            <div class="menu-container">

              <!-- Buttons -->
              <button class="btn btn-outline-primary mr-2" (click)="setMenu('dates')" [disabled]="loadingReport || reportError">
                <i class="far fa-calendar-alt mr-2"></i>Date Range: <b>{{ startDate | date: 'dd/MM/yy' }}</b> to <b>{{ endDate | date: 'dd/MM/yy' }}</b>
              </button>
              <button [class]="selectedFilters.length ? 'btn btn-primary mr-2' : 'btn btn-outline-primary mr-2'" (click)="setMenu('filters')" [disabled]="loadingReport || reportError">
                <i class="far fa-filter mr-2"></i>
                {{ selectedFilters.length ? 'Change Filters' : 'Add Filters' }}
                <span *ngIf="selectedFilters.length" class="badge badge-info ml-1">{{ selectedFilters.length }}</span>
              </button>

              <!-- Menus -->
              <div *ngIf="selectedMenu.filters" class="card text-white bg-primary card-filters">

                <div *ngIf="!selectedFilters.length" class="text-secondary text-center mt-2 mb-4">
                  There are no filters selected. Add one using the drop-down below...
                </div>
          
                <!-- Selected Filters -->
                <div *ngIf="selectedFilters.length">
                  <div *ngFor="let filter of selectedFilters; let i = index" class="card text-white bg-primary border-light mb-2">     
                    <div class="card-body">
                      <h5 class="card-title">{{ filter.filterName }}</h5>
                      <div *ngIf="!filter.collapse" [ngSwitch]="filter.dataType">
          
                        <!-- Number -->
                        <div *ngSwitchCase="'N1'">
                          <div class="my-2">
                            <span *ngFor="let type of ['Equal to','Greater than','Less than','Between']" (click)="setFilterType(filter.dataItemId, type)" [class]="filter.selectedFilterType && filter.selectedFilterType === type ? 'btn btn-sm btn-success mr-2 mb-2' : 'btn btn-sm btn-outline-light mr-2 mb-2'">
                              {{ type }}
                            </span>
                          </div>
                          <form id="filter_{{i}}">
                            <div class="row">
                              <div class="col-sm-6 col-12" *ngIf="filter.selectedFilterType === 'Equal to'">
                                <div class="form-group">
                                  <label for="numberEquals">Equal to</label>
                                  <input type="number" name="numberEquals" class="form-control" id="filter_{{i}}_numberEquals" (keyup)="updateFilter(filter.dataItemId, 'numberEquals', $event.target.value)" [value]="filter.params && filter.params['numberEquals'] ? filter.params['numberEquals'] : null">
                                </div>  
                              </div>
                              <div class="col-sm-6 col-12" *ngIf="filter.selectedFilterType === 'Greater than' || filter.selectedFilterType === 'Between'">
                                <div class="form-group">
                                  <label for="numberGreaterThan">Greater than</label>
                                  <input type="number" name="numberGreaterThan" class="form-control" id="filter_{{i}}_numberGreaterThan" (keyup)="updateFilter(filter.dataItemId, 'numberGreaterThan', $event.target.value)" [value]="filter.params && filter.params['numberGreaterThan'] ? filter.params['numberGreaterThan'] : null">
                                </div>
                              </div>
                              <div class="col-sm-6 col-12" *ngIf="filter.selectedFilterType === 'Less than' || filter.selectedFilterType === 'Between'">
                                <div class="form-group">
                                  <label for="numberLessThan">Less than</label>
                                  <input type="number" name="numberLessThan" class="form-control" id="filter_{{i}}_numberLessThan" (keyup)="updateFilter(filter.dataItemId, 'numberLessThan', $event.target.value)" [value]="filter.params && filter.params['numberLessThan'] ? filter.params['numberLessThan'] : null">
                                </div>
                              </div>
                            </div>           
                          </form>
                        </div>
                        
                        <!-- Yes/No-->
                        <div *ngSwitchCase="'YN'" class="mt-2">
                          <span *ngFor="let item of [{ type: 'Y', name: 'Yes' }, { type: 'N', name: 'No' }]"
                            [class]="filter.selectedFilterType && filter.selectedFilterType === item.type ? 'btn btn-sm btn-success mr-2 mb-2' : 'btn btn-sm btn-outline-light mr-2 mb-2'"
                            (click)="setFilterType(filter.dataItemId, item.type); updateFilter(filter.dataItemId, 'yesNo', item.type)">
                            {{ item.name }}
                          </span>
                        </div>
          
                        <!-- List Item -->
                        <div *ngSwitchCase="'LS'" class="mt-2">
                          <span *ngFor="let item of filter.listItems"
                            [class]="filter.selectedFilterType && filter.selectedFilterType === item.listItemName ? 'btn btn-sm btn-success mr-2 mb-2' : 'btn btn-sm btn-outline-light mr-2 mb-2'"
                            (click)="setFilterType(filter.dataItemId, item.listItemName); updateFilter(filter.dataItemId, 'listItem', item.listItemId)">
                            {{ item.listItemName }}
                          </span>
                        </div>
          
                        <!-- Date -->
                        <div *ngSwitchCase="'DT'" class="mt-2">
                          <div class="row">
                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <label for="dateFrom">From</label>
                                <input type="date" name="dateFrom" class="form-control" (input)="updateFilter(filter.dataItemId, 'dateFrom', $event.target.value)" [value]="filter.params && filter.params['dateFrom'] ? filter.params['dateFrom'] : null">
                              </div>
                            </div>
                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <label for="dateTo">To</label>
                                <input type="date" name="dateTo" class="form-control" (input)="updateFilter(filter.dataItemId, 'dateTo', $event.target.value)" [value]="filter.params && filter.params['dateTo'] ? filter.params['dateTo'] : null">
                              </div>
                            </div>
                          </div>
                        </div>
          
                        <!-- Time -->
                        <div *ngSwitchCase="'TM'" class="mt-2">
                          <div class="row">
                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <label for="dateFrom">From</label>
                                <input type="time" name="dateFrom" class="form-control" (input)="updateFilter(filter.dataItemId, 'dateFrom', $event.target.value)" [value]="filter.params && filter.params['dateFrom'] ? filter.params['dateFrom'] : null">
                              </div>
                            </div>
                            <div class="col-sm-6 col-12">
                              <div class="form-group">
                                <label for="dateTo">To</label>
                                <input type="time" name="dateTo" class="form-control" (input)="updateFilter(filter.dataItemId, 'dateTo', $event.target.value)" [value]="filter.params && filter.params['dateTo'] ? filter.params['dateTo'] : null">
                              </div>
                            </div>
                          </div>
                        </div>
          
                      </div>
                      <span class="text-secondary mr-4 hover-cursor" (click)="filter.collapse = !filter.collapse">
                        <i [class]="filter.collapse ? 'far fa-arrow-alt-circle-down mr-2' : 'far fa-arrow-alt-circle-up mr-2'"></i>{{ filter.collapse ? 'Expand' : 'Collapse' }} options
                      </span>
                      <span class="text-secondary hover-cursor" (click)="removeFilter(filter.dataItemId)">
                        <i class="far fa-times-circle mr-2"></i>Remove this filter
                      </span>
                    </div>
                  </div>
                </div>
          
                <!-- Filter Select -->
                <div class="form-group">
                  <select name="filterName" class="form-control" (change)="setFilter($event.target.value)">
                    <option [value]="null">Add {{ selectedFilters.length ? 'another' : 'a' }} filter...</option>
                    <option *ngFor="let filter of reportFilters" [value]="filter.dataItemId">{{ filter.filterName }}</option>
                  </select>
                </div>

                <!-- Update, Clear and Presets -->
                <div class="presets-container">
                  <span (click)="getData(reportDetails.reportId, organisation.organisationId, selectedFiltersParams)" class="btn btn-success">Update</span>
                  <span (click)="clearFilters()" class="btn btn-outline-light ml-2">Clear</span>
                  <span (click)="setMenu('presetSelect')" class="btn btn-outline-light float-right"><i class="far fa-stars mr-2"></i>Presets</span>
                  <div *ngIf="selectedMenu.presetSelect" class="presets">
                    <!-- TODO: Add preset params to stored array (or DB) and loop -->
                    <div class="text-secondary hover-cursor my-1" (click)="setPreset(46, 'numberLessThan', '21', 'Less than'); selectedMenu.presetSelect = false"><i class="far fa-plus-circle mr-2"></i>Under 21s only</div>
                    <div class="text-secondary hover-cursor my-1" (click)="setPreset(64, 'yesNo', 'Y', 'Y'); selectedMenu.presetSelect = false"><i class="far fa-plus-circle mr-2"></i>All Out of Area Placements</div>
                  </div>
                </div>
          
              </div>
              
              <!-- DATES -->
              <div *ngIf="selectedMenu.dates" class="card text-white bg-primary card-filters">
                
                <div class="row">
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label for="dateFrom">From</label>
                      <input #startDateInput type="date" name="dateFrom" class="form-control" (input)="updateFilter(1, 'dateFrom', startDateInput.value)" [value]="startDate ? startDate : null">
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label for="dateTo">To</label>
                      <input #endDateInput type="date" name="dateTo" class="form-control" (input)="updateFilter(1, 'dateTo', endDateInput.value)" [value]="endDate ? endDate : null">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <span (click)="getData(reportDetails.reportId, organisation.organisationId, selectedFiltersParams)" class="btn btn-success mb-2">
                      Update
                    </span>
                    <span class="btn btn-outline-light ml-2 mb-2" (click)="reset()">
                      Reset
                    </span>
                  </div>
                </div>
              
              </div>

            </div>

          </div>

        </div>

        <!-- REPORT -->
        <div class="row mt-2 justify-content-center report-container">

          <div class="col-md-6 col-10">
            <div class="alert alert-primary text-center alert-report" *ngIf="loadingReport && !reportError">
              Loading report...
            </div>
            <div class="alert alert-danger text-center alert-report" *ngIf="reportError" (click)="reset()">
              {{ reportError }}
            </div>
          </div>

          <div class="col-md-12" *ngIf="reportDetails" [style.opacity]="loadingReport ? 0.25 : 1">
            <div class="row">
              <div class="col-md-12" *ngIf="timeSeriesData && !reportError">
                <div class="chart">
                  <div class="row">
                    <div class="col-8">
                      <h5>
                        Time Series of Cases by {{ reportDetails.reportName }}<br>
                        <small>
                          <ng-container *ngIf="!noReportData">
                            between {{ startDate | date: 'd MMMM y' }} and {{ endDate | date: 'd MMMM y' }}
                          </ng-container>
                          <ng-container *ngIf="noReportData" >
                            <span class="text-danger" >There is no data for this filter combination</span>
                          </ng-container>
                        </small>
                      </h5>
                    </div>
                    <div class="col-4 text-right">
                      <div class="btn btn-sm btn-outline-secondary" (click)="timeSeriesChart.zoomOut(); zoomed = false" *ngIf="zoomed">
                        <i class="fas fa-search-minus"></i> Reset Zoom
                      </div>
                      <button class="btn btn-sm btn-outline-primary ml-2" *ngIf="timeIntervalType === 'day'" disabled>
                        <span *ngIf="loadingCaseRefs"><i class="fas fa-spinner fa-spin mr-1"></i>Loading case references</span>
                        <span *ngIf="!loadingCaseRefs">Click on a day to retrieve case references</span>
                      </button>
                    </div>
                  </div>
                  <!-- Legend -->
                  <div class="row">
                    <div class="col-12">
                      <highcharts-chart [Highcharts]="Highcharts" [options]="timeSeriesChartOptions" class="height-200" (chartInstance)="createLegend($event)" (click)="updateTimeSeries($event.point.category, timeIntervalType)"></highcharts-chart>
                      <!-- <highcharts-chart [Highcharts]="Highcharts" [options]="lineChartOptions" (chartInstance)="createLegend($event)" class="height-200"></highcharts-chart>  -->
                      <div *ngIf="legend && legend.length" class="selectors">
                        <div *ngFor="let l of legend; let i = index" [class]="l.visibility ? 'btn btn-sm btn-outline-primary mr-2 mt-2' : 'btn btn-sm btn-outline-secondary mr-2 mt-2'" (click)="toggleSeriesVisibility(i)">
                            <i class="fa fa-square mr-1" [style.color]="l.color"></i>{{ l.name }}
                        </div>
                        <div class="btn btn-sm btn-outline-secondary mr-2 mt-2" (click)="toggleAllSeries()">
                          <i class="fa fa-square mr-1"></i>Toggle All
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4" *ngIf="reportDetails.reportType !== 'DATEDIFF' && timeSeriesData && !reportError">
                <div class="chart">
                  <h5>
                    Number of Cases by {{ reportDetails.reportName }}<br>
                    <small>
                      <ng-container *ngIf="!noReportData">
                        between {{ startDate | date: 'd MMMM y' }} and {{ endDate | date: 'd MMMM y' }}
                      </ng-container>
                      <ng-container *ngIf="noReportData">
                        <span class="text-danger">There is no data for this filter combination</span>
                      </ng-container>
                    </small>
                  </h5>
                  <highcharts-chart [Highcharts]="Highcharts" [options]="columnChartOptions" class="height-400"></highcharts-chart>
                </div>
              </div>
              <div class="col-md-4" *ngIf="reportDetails.reportType !== 'DATEDIFF' && timeSeriesData && !reportError">
                <div class="chart">
                  <h5>
                    Percentage of Cases by {{ reportDetails.reportName }}<br>
                    <small>
                      <ng-container *ngIf="!noReportData">
                        between {{ startDate | date: 'd MMMM y' }} and {{ endDate | date: 'd MMMM y' }}
                      </ng-container>
                      <ng-container *ngIf="noReportData">
                        <span class="text-danger">There is no data for this filter combination</span>
                      </ng-container>
                    </small>
                  </h5>
                  <highcharts-chart [Highcharts]="Highcharts" [options]="pieChartOptions" class="height-400"></highcharts-chart>
                </div>
              </div>
              <div class="col-md-4" *ngIf="reportDetails.reportType !== 'DATEDIFF' && timeSeriesData && !reportError">
                <div class="chart table-container">
                  <h5 class="mb-4">
                    Data Table<br>
                    <small>
                      <ng-container *ngIf="!noReportData">
                        between {{ startDate | date: 'd MMMM y' }} and {{ endDate | date: 'd MMMM y' }}
                      </ng-container>
                      <ng-container *ngIf="noReportData">
                        <span class="text-danger">There is no data for this filter combination</span>
                      </ng-container>
                    </small>
                  </h5>
                  <div class="table-responsive-md">
                    <table class="table table-sm">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col" style="width:50%">Category</th>
                          <th scope="col" class="text-right" style="width:25%">Count</th>
                          <th scope="col" class="text-right" style="width:25%">Perc.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let t of reportTable">
                          <td>
                            <i class="fas fa-square mr-2" [style.color]="t.color"></i>
                            {{ t.name }}
                          </td>
                          <td class="text-right">{{ t.count }}</td>
                          <td class="text-right">{{ t.y | number: '1.1-1' }}%</td>
                        </tr>
                        <tr class="table-secondary font-weight-bold">
                          <td>{{ reportTableTotals.name }}</td>
                          <td class="text-right">{{ reportTableTotals.count }}</td>
                          <td class="text-right">{{ reportTableTotals.y | number: '1.1-1' }}%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-12" *ngIf="reportDetails.reportType === 'DATEDIFF' && timeSeriesData && !reportError">
                <div class="chart">
                  <h5>
                    {{ reportDetails.reportName }}
                  </h5>
                  <highcharts-chart [Highcharts]="Highcharts" [options]="differenceChartOptions" class="height-400"></highcharts-chart>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</div>

<!-- Case Data -->
<div *ngIf="caseData" class="card-fixed-container">
  <div class="card card-fixed text-white bg-primary">
    <div class="position-relative">
      <div class="closer" (click)="caseData = null">&times;</div>
      <h4 class="mb-3">
        Case Notes for <strong>{{ caseDataDate | date: 'd MMMM yyyy' }}</strong>
      </h4>
    </div>
    <table class="table table-sm text-white">
      <thead>
        <tr>
          <th>Case Reference</th>
          <th>Response</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let c of caseData">
          <td>{{ c.caseReference }}</td>
          <td>{{ c.response }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>