import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ErrorComponent } from './error/error.component';

import { AuthGuardService as AuthGuard } from './_services/auth-guard.service';
import { ToolkitComponent } from './toolkit/toolkit.component';
import { ProjectComponent } from './project/project.component';
import { AboutComponent } from './about/about.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  { 
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [AuthGuard] 
  },
  { 
    path: 'about',
    component: AboutComponent,
    canActivate: [AuthGuard] 
  },
  { 
    path: 'toolkit',
    component: ToolkitComponent,
    canActivate: [AuthGuard] 
  },
  { 
    path: 'project/:id',
    component: ProjectComponent,
    canActivate: [AuthGuard] 
  },
  { path: '**', 
    component: ErrorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
