import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { UserService } from './_services/user.service';
import { DataService } from './_services/data.service';
import { AuthGuardService } from './_services/auth-guard.service';
import { JwtModule } from '@auth0/angular-jwt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WelcomeComponent } from './welcome/welcome.component';
import { ErrorComponent } from './error/error.component';
import { ToolkitComponent } from './toolkit/toolkit.component';
import { ProjectComponent } from './project/project.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 

import { NgPipesModule } from 'ngx-pipes';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { AboutComponent } from './about/about.component';

export function tokenGetter() {
  return localStorage.getItem('mhlUserToken');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    WelcomeComponent,
    ErrorComponent,
    ToolkitComponent,
    ProjectComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    HighchartsChartModule,
    NgxMapboxGLModule.withConfig({ accessToken: 'pk.eyJ1IjoiZGh1Z2hlc2JtYyIsImEiOiJjazQ5bXAxYW8wNmM2M2ZwY3N2aTR4emU3In0.7rhqvNQKGZ_ciuuxVAdV3A' }),
  ],
  providers: [
    AuthGuardService,
    UserService,
    DataService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
