import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, animate, style } from '@angular/animations'
import { HttpClient } from '@angular/common/http';

// Services
import { DataService } from '../_services/data.service';

// Pipes
import { DatePipe } from '@angular/common';
import { GroupByPipe, OrderByPipe, UniquePipe, SumPipe, FilterByPipe } from 'ngx-pipes';

// Packages
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import extent from 'turf-extent';
import { Map } from 'mapbox-gl';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  providers: [ GroupByPipe, OrderByPipe, UniquePipe, SumPipe, FilterByPipe, DatePipe ],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('250ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('250ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ])
  ]
})
export class ProjectComponent implements OnInit {

  // Loading, Error
  loading: boolean = false;
  loading_national: boolean = false;
  loading_timeseries: boolean = false;
  loading_map: boolean = false;
  loading_split: boolean = false; loading_split_change: boolean = false;
  error: string;

  // Dropdowns
  dropdown: any = { any: false, tiers: false, organisations: false, categories: false, filters: false, drawer: false };
  tiers: any; searchableTiers: any; tier: any;
  benchmarks: any; benchmark: any;
  organisations: any;
  organisation: any;

  // Details
  details: any; reportId: number;
  nationalReport: boolean = false;
  periods: any; period: any; paramPeriod = [];
  dates: any = { start: null, end: null };
  colors: any = [
    "#e03616","#f1a208","#00a878","#111b54","#a01a7d","#09c2de",
    "#f39886","#fbd281","#54ffcf","#566adc","#e873c9","#78e8f9",
    "#6f1c0b","#795204","#00543c","#090e2a","#4f0d3d","#05606e"            
  ];
  nationalColors: any = [
    "#111b54", "#e03616","#f1a208"
  ];
  set: any;

  // Categories and Filters
  categories: any;
  filters: any;
  splits: any;
  setFilters: any = [];
  paramFilters: any = [];
  filterCount: number = 0;

  // Data
  table: any; totals: any;
  nationalTable: any;
  hovered: any;

  // Charts
  Highcharts: typeof Highcharts = Highcharts;
  columnChartOptions: Highcharts.Options;
  pieChartOptions: Highcharts.Options;
  timeSeriesChartOptions: Highcharts.Options;
  splitChartOptions: Highcharts.Options;

  // Maps
  map: any; mapboxMap: Map; mapboxOptions: any;
  mbHover = ['==', 'name', ''];

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private data: DataService,
    public group: GroupByPipe,
    public sum: SumPipe,
    public filterBy: FilterByPipe,
    public date: DatePipe
  ) { }

  ngOnInit() {
    let projectId = +this.route.snapshot.paramMap.get('id');
    this.getOrganisations(projectId);
  }

  getOrganisations(projectId: number) {
    this.data.getOrganisations(projectId).subscribe(
      r => { 
        let organisations = r.data.organisationList;
        if (organisations) {
          this.setOrganisation(organisations[0]);
          this.getOrganisationTiers(projectId);
        } else {
          this.error = "You do not have the necessary permissions to view this page."
        }
      },
      e => {
        console.log(e);
      }
    )
  }

  getOrganisationTiers(projectId: number) {
    this.data.getOrganisationTiers(projectId).subscribe(
      r => { 
        // TODO: Add role count to this end-point
        this.organisations = r.data.organisationHierarchy;
        this.getTiers(projectId);
      },
      e => {
        console.log(e);
      }
    )
  }

  getTiers(projectId: number) {
    this.data.getTiers(projectId).subscribe(
      r => { 
        this.tiers = r.data.tierList;
        this.searchableTiers = this.formatTiers(this.tiers);

        // Open saved report/tier
        let tierId = +this.route.snapshot.queryParams.tier;
        if (tierId && this.searchableTiers.length) {
          let tier = this.searchableTiers.find(st => st.tierIdMap.includes(tierId));
          this.getDetails(tier.reportId, tier);
        } else {
          this.router.navigate([], { queryParams: { tier: null }, queryParamsHandling: 'merge' });
        }
      },
      e => { 
        console.log(e);
      }
    )
  }

  getDetails(reportId: number, tier?: any) {
    this.data.getDetails(reportId).subscribe(
      r => { 
        // Reset
        this.set = null;
        // Set details
        this.details = r.data.reportDetails;
        this.reportId = reportId;
        // Set categories, filters and splits
        this.filters = this.formatFilters(r.data.reportFilters);
        this.splits = this.filters.secondaryFilters;
        // Set periods
        let timeParam = this.details.reportParameters.find(p => p.parameterType == 'T'); 
        this.periods = this.formatPeriods(r.data.reportDetails.minMaxDates, timeParam);
        this.period = this.periods['months'][0];
        // Close menu
        this.dropdown.tiers = false;
        // Add tier to URL
        this.router.navigate([], { queryParams: { tier: tier.tierId }, queryParamsHandling: 'merge' });
        // Get data
        this.setData(this.details.reportId, this.organisation.organisationId, this.period, this.paramFilters);
      },
      e => {
        console.log(e);
      }
    )
  }

  getData(reportId: number, organisationId: number, filters?: any) {
    // Close menu and load
    this.dropdown.filters = false;
    this.loading = true;

    // Reset map
    this.map = null;
    this.mapboxMap = null;
    this.mapboxOptions = null;

    // Get data
    this.data.getData(reportId, organisationId, filters).subscribe(
      r => { 
        let reportData = r.data.reportData;
        
        // Column chart (raw data)
        let columnSeries = reportData.map((r, i) => { return { y: r.caseCount, color: this.colors[i] } } );
        let columnCategories = reportData.map(r => r.seriesName );
        this.createColumnChart(columnCategories, [{ name: this.details.reportName, data: columnSeries }]); 

        // Pie chart (% conversion)
        const totalCount: number = this.sum.transform(reportData.map(rd => rd.caseCount));
        let pieSeries = reportData.map((r, i) => { 
          return { 
            name: r.seriesName,
            count: r.caseCount,
            y: (r.caseCount / totalCount) * 100, 
            color: this.colors[i] 
          } 
        });
        this.createPieChart([{ name: this.details.reportName, colorByPoint: true, innerSize: '50%', data: pieSeries }]);

        // Create data table with totals
        const totalPerc: number = this.sum.transform(pieSeries.map(rd => rd.y));
        let reportTotals = {
          name: 'Totals',
          count: totalCount,
          y: totalPerc
        };
        this.table = pieSeries;
        this.totals = reportTotals;

        // Get time series data
        this.getTimeSeriesData(reportId, this.organisation.organisationId, 'month', filters);
      },
      e => {
        console.log(e);
        this.error = "There has been an error retrieving the data."
        this.loading = false;
      }
    )
  }

  getTimeSeriesData(reportId: number, organisationId: number, timeIntervalType?: string, filters?: any) {
    this.data.getTimeSeriesData(reportId, organisationId, 1, timeIntervalType, filters).subscribe(
      r => {
        let timeSeriesData = this.formatTimeSeries(r.data.reportTimeseries, timeIntervalType);
        this.createTimeSeriesChart(timeSeriesData.categories, timeSeriesData.data);
        this.loading = false;
      },
      e => {
        console.log(e);
        this.error = "There has been an error retrieving the time series data."
        this.loading = false;
      }
    )
  }

  getNationalData(reportId: number, organisationId: number, period: any, filters?: any) {
    this.loading = true;
    this.loading_national = true;
    this.loading_map = true;
    this.data.getNationalData(reportId, organisationId, period.periodParams, filters).subscribe(
      r => {

        let nationalData = r.data.reportData.reverse();

        let data = [];
        nationalData.forEach(d => {
          data.push({
            y: d.result,
            organisationName: d.organisationName,
            isHighlighted: d.isHighlighted,
            color: d.isHighlighted === 'Y' ? '#e03616' : "#111b54",
            code: d.anonymousCode
          })
        })
        
        // Set highlighted
        let highlighted = data.find(d => d.isHighlighted === 'Y');

        this.createColumnChart(
          nationalData.map(d => d.anonymousCode),
          [{ 
            name: this.details.reportName,
            data: data
          }]
        );
        
        this.createMap(nationalData, period, highlighted ? highlighted.code : null);
        this.nationalTable = data;
      },
      e => {
        console.log(e);
      },
      () => {
        this.loading_national = false;
        this.loading_map = false;
        this.getNationalTimeSeriesData(reportId, organisationId, this.period.periodType, filters);
      }
    )
  }

  getNationalTimeSeriesData(reportId: number, organisationId: number, timeIntervalType: string, filters?) {
    this.loading_timeseries = true;
    this.data.getNationalTimeSeriesData(reportId, organisationId, 1, timeIntervalType, filters).subscribe(
      r => {
        let nationalData = r.data.reportData;
        this.createTimeSeriesChart(
          nationalData.map(d => this.date.transform(d.datePeriod, this.period.periodFormat)), 
          [
            { name: 'National', data: nationalData.map(d => d.nationalAverage) },
            { name: this.organisation.organisationName, data: nationalData.map(d => d.organisationValue) },
          ]
        )
        let table = [];
        nationalData.forEach(d => {
          table.push({

            period: this.date.transform(d.datePeriod, this.period.periodFormat),
            national: d.nationalAverage,
            organisation: d.organisationValue
          })
        });
        this.table = table;
        this.loading = false;
      },
      e => {
        console.log(e);
        this.error = "There has been an error retrieving the time series data."
        this.loading = false;
      },
      () => {
        this.loading_timeseries = false;
      }
    )
  }

  getNationalSplitData(selectedSeriesId: number, organisationId: number, period: any, filters?: any) {
    this.data.getNationalSplitData(selectedSeriesId, organisationId, period.periodParams, filters).subscribe(
      r => { 
        let nationalData = r.data.reportData;

        let categories = nationalData.map(d => d.seriesName);
        let series = [
          {
            name: 'National',
            data: nationalData.map(d => d.nationalAverage),
            color: this.nationalColors[0]
          },
          {
            name: 'Organisation',
            data: nationalData.map(d => d.organisationValue),
            color: this.nationalColors[1]
          },
        ]
        this.createSplitChart(categories, series)
       },
      e => {
        console.log(e)
      },
      () => {
        this.loading_split = false;
        this.loading_split_change = false;
      }
    )
  }

  // SETS

  setTier(tier: any) {
    this.tier = tier;
    this.benchmarks = tier.childTiers.filter(ct => ct.tierTypeId == 4);
    this.benchmark = this.benchmarks[0];
    this.getDetails(this.benchmark.reportId, tier.tierId);
  }

  setData(reportId: number, organisationId: number, period: any, filters?: any) {
    if (this.details.nationalReport == 'Y') {
      this.nationalReport = true;
      this.colors = this.nationalColors;
      this.getNationalData(reportId, organisationId, period, filters);
      this.setSplit(this.splits[0], organisationId, period, filters, false);
      // Save set details
      let set = { reportId: reportId, organisationId: organisationId, period: period, allFilters: this.filters, filters: this.setFilters, paramFilters: filters, filterCount: this.filterCount };
      this.set = JSON.parse(JSON.stringify(set));
      this.benchmark = this.benchmarks.find(b => b.reportId === reportId);
    } else {
      this.getData(reportId, organisationId, filters);
    }
    this.setDropdown('any');
  }

  setPeriod(period) {
    this.period = period;
  }

  setReport(benchmark) {
    this.reportId = benchmark.reportId;
  }

  setOrganisation(organisation: any) {
    // Set organisation
    this.organisation = organisation;
    // Close menu
    this.setDropdown('any');
    // Refresh data if report selected
    if (this.details) {
      this.setData(this.details.reportId, this.organisation.organisationId, this.period, this.paramFilters);
    }
  }

  setFilter(filter) {
    let findFilter = this.filters.allFilters.find(f => f.dataItemId == filter);
    let findSetFilter = this.setFilters.find(f => f.dataItemId == filter);
    if (!findSetFilter) {
      this.setFilters.push(findFilter);
    }
  }

  setFilterValue(filter: any, filterType: string, filterId: number, filterValue: number) {

    // Param to add to filters
    let param = {
      filterType: filterType,
      filterId: filterId,
      filterValue: filterValue
    }
 
    if (filterType == 'listItem' || filterType == 'yesNo') {
      let paramIndex = filter.params.findIndex(p => p.filterId == filterId && p.filterValue == filterValue);
      if (paramIndex > -1) {
        filter.params.splice(paramIndex, 1);
      } else {
        filter.params.push(param);
      }
    }

    if (filterType.includes('date')) {
      let paramIndex = filter.params.findIndex(p => p.filterId == filterId && p.filterType == filterType);
      if (paramIndex > -1) { filter.params.splice(paramIndex, 1) };
      filter.params.push(param);
    }
    
    // Set filter values for highlight
    filter.active = filter.params.map(f => f.filterValue);
    
    // Create filter params
    let params = [];
    this.setFilters.forEach(f => {
      if (f.params.length) {
        f.params.forEach(p => { params.push(p) })
      }
    });

    // Set params
    this.paramFilters = params;

    // Set count
    this.filterCount = this.setFilters.filter(sf => sf.filterClassId == 2).length;

    // TODO: Save to sessionStorage
  }

  setSplit(split: any, organisationId: number, period: any, filters?: any, change?: boolean) {
    // Change split
    if (split && this.splits.length) {
      // Correct load type
      if (change === true) {
        this.loading_split_change = true;
      } else {
        this.loading_split = true;
      }
      this.splits.forEach(s => s.split = false);
      split.split = true;
      this.getNationalSplitData(split.dataItemId, organisationId, period, filters);
    }
  }

  setDropdown(type) {
    if (type === 'any') {
      Object.keys(this.dropdown).forEach(m => {
        this.dropdown[m] = false;
      })
    } else {
      this.dropdown.any = !this.dropdown.any;
      this.dropdown[type] = !this.dropdown[type];
    }
  }

  // DESTROYS

  destroyFilter(filter) {
    // Remove from filters
    let filterIndex = this.setFilters.indexOf(filter);
    this.setFilters.splice(filterIndex, 1);
    // Remove from filter params
    this.paramFilters = this.paramFilters.filter(pf => pf.filterId !== filter.dataItemId);
    // Set count
    this.filterCount = this.setFilters.filter(sf => sf.filterClassId == 2).length;
  }

  destroyDrawer(set) {
    // Close drawer
    this.setDropdown('any');
    // Reset variables
    this.reportId = set.reportId;
    this.filterCount = set.filterCount;
    this.filters = set.allFilters;
    this.setFilters = set.filters;
    this.period = set.period;
    this.paramFilters = set.paramFilters;
  }

  // CHARTS

  createColumnChart(categories: any, series: any) {
    this.columnChartOptions = {
      lang: {
        thousandsSep: ','
      },
      chart: {
        type: 'column',
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: 'transparent'
      },
      colors: this.colors,
      tooltip: {
        enabled: false
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        categories: categories,
        gridLineColor: '#EEF1F8'
      },
      yAxis: {
        gridLineColor: '#EEF1F8',
        title: {
          text: null
        },
        allowDecimals: false
      },
      plotOptions: {
        series: {
          colorByPoint: true,
          animation: false,
          marker: {
            enabled: false
          },
          point: { 
            events: {
              mouseOver: ((e) => {
                this.mouseOver(e, 'chart');
              }),
              mouseOut: ((e) => { 
                this.mouseLeave();
              })
            }
          }
        }
      },
      series: series
    };

  }

  createSplitChart(categories: any, series: any) {
    this.splitChartOptions = {
      lang: {
        thousandsSep: ','
      },
      chart: {
        type: 'column',
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: 'transparent'
      },
      colors: this.nationalColors,
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{point.x}</div><table>',
        pointFormat: '<tr><td style="padding:0.25em 0;"><i class="fas fa-square mr-2" style="color:{series.color}"></i>{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 2,
        style: { color: '#FFFFFF' },
        shared: true
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        categories: categories,
        gridLineColor: '#EEF1F8',
        crosshair: true,
      },
      yAxis: {
        gridLineColor: '#EEF1F8',
        title: {
          text: null
        },
        allowDecimals: false
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            enabled: false
          }
        }
      },
      series: series
    };

  }

  createPieChart(series: any) {
    this.pieChartOptions = {
      chart: {
        type: 'pie',
        animation: false,
        style: { fontFamily: '"Helvetica Neue", Arial, sans-serif' },
        backgroundColor: 'transparent'
      },
      colors: this.colors,
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{series.name}</div><table>',
        pointFormat: '<tr><td><i class="fas fa-square mr-2" style="color:{point.color}"></i>{point.name}: {point.y}%</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 0,
        style: { color: '#FFFFFF' }
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            enabled: false
          }
        },
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          }
        }
      },
      series: series
    };
  }

  createTimeSeriesChart(categories: any, series: any) {
    this.timeSeriesChartOptions = {
      chart: {
        type: 'line',
        //zoomType: 'x', 
        animation: false,
        style: { 
          fontFamily: '"Helvetica Neue", Arial, sans-serif',
          cursor: 'pointer'
        },
        backgroundColor: 'transparent',
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        },
        // events: {
        //   load: ((e) => { 
        //     this.timeSeriesChart = e.target;
        //   }),
        //   click: ((e) => {
        //     let categoryIndex = Math.ceil(e.xAxis[0].value);
        //     let categoryName = this.timeSeriesChart.axes[0].categories[categoryIndex];
        //     this.updateTimeSeries({ point: { category: categoryName } }, this.timeIntervalType);
        //   })
        // },
      },
      colors: this.colors,
      tooltip: {
        useHTML: true,
        backgroundColor: '#000000',
        borderWidth: 0,
        shadow: false,
        headerFormat: '<div style="margin-bottom:5px;font-size:14px;">{point.x}</div><table>',
        pointFormat: '<tr><td style="padding:0.25em 0;"><i class="fas fa-square mr-2" style="color:{series.color}"></i>{series.name}:</td><td style="text-align:right;padding:0.25em 0.5em;">{point.y}</td></tr>',
        footerFormat: '</table></div>',
        valueDecimals: 2,
        style: { color: '#FFFFFF' },
        shared: true
      },
      title: {
          text: ''
      },
      exporting: { enabled: false },
      legend: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        categories: categories,
        gridLineColor: '#EEF1F8',
        crosshair: true
      },
      yAxis: {
        gridLineColor: '#EEF1F8',
        title: {
          text: null
        },
        allowDecimals: false
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            enabled: false
          }
        }
      },
      series: series
    };
  }

  // MAPS

  createMap(data, period, highlighted?) {

    // Police Force area maps (ultra-generalised)
    // Source: https://geoportal.statistics.gov.uk/search?collection=Dataset&sort=name&tags=all(BDY_PFA)
    const maps = [
      { periodFy: 2016, url: 'https://opendata.arcgis.com/datasets/1a9d6ec188994711b8345118af63923e_0.geojson', code: 'PFA16CD' },
      { periodFy: 2017, url: 'https://opendata.arcgis.com/datasets/bb12117b37134a03874c55175cf7f4bc_4.geojson', code: 'pfa17cd' },
      { periodFy: 2018, url: 'https://opendata.arcgis.com/datasets/9787c37f88324077bb0c376b3b366ea2_0.geojson', code: 'PFA18CD' },
      { periodFy: 2019, url: 'https://opendata.arcgis.com/datasets/e79b2d65db914d83899b1cf5b09385ad_0.geojson', code: 'PFA19CD' },
      { periodFy: 2020, url: 'https://opendata.arcgis.com/datasets/e79b2d65db914d83899b1cf5b09385ad_0.geojson', code: 'PFA19CD' } // TODO: Needs updating to 2020 map
    ];

    // Select correct map and code for period
    this.map = maps.find(m => m.periodFy === period.periodFy);

    // Calculate min/average/max 
    // TODO: Move to format.service
    let values = data.map(d => d.result),
        sorted = values.sort((a, b) => { return a-b }),
        half = Math.floor(sorted.length / 2),
        count = sorted.length,
        mean = sorted.reduce((a, b) => a + b, 0) / count,
        median = count % 2 ? sorted[half] : (sorted[half - 1] + sorted[half]) / 2,
        min = sorted.reduce((a, b) => Math.min(a, b)),
        max = sorted.reduce((a, b) => Math.max(a, b)),
        per25 = Math.floor(count * 0.25) - 1,
        per75 = Math.floor(count * 0.75) - 1,
        quartile25 = sorted[per25],
        quartile75 = sorted[per75]

    let averages = {
      min: min,
      max: max,
      mean: mean,
      median: median,
      count: count,
      quartile25: quartile25,
      quartile75: quartile75
    };

    if (this.map) {
      this.http.get(this.map.url).subscribe(
        (geojson: any) => {

          // Get organisations with data
          let dataFeatures = [];
          data.forEach(d => {
            let matchFeature = geojson.features.find(f => f.properties[this.map.code] === d.anonymousCode);
            if (matchFeature) {
              matchFeature.properties.value = d.result;
              dataFeatures.push(matchFeature);
            }
          });
          geojson.features = dataFeatures;

          // Get bounds
          let bounds = extent(geojson);

          // Get highlighted organisation
          let highlightedGeojson: any = { "type": "FeatureCollection", "features": [] };
          if (highlighted) {
            let matchFeature = geojson.features.find(f => f.properties[this.map.code] === highlighted);
            if (matchFeature) {
              highlightedGeojson.features.push(matchFeature);
            }
          }

          // Set options
          this.mapboxOptions = {
            style: 'mapbox://styles/dhughesbmc/ck49sb7ni05c51cn4axz029os',      
            //zoom: 4.75,
            //center: [-2.395860193205664, 52.98750938728335],
            logoPosition: 'top-right',
            bounds: bounds,
            boundsOptions: { 
              padding: { top: 20, bottom: 20, left: 20, right: 20 }
            },
            organisations: {
              type: 'fill',
              source: {
                type: 'geojson',
                data: geojson,
              },
              paint: {        
                'fill-color': [
                  'interpolate',
                  ['exponential', 0],
                  ['get', 'value'],
                  averages.min, '#c3c6d4',
                  averages.quartile25, '#888da9',
                  averages.median, '#4d547f',
                  averages.quartile75, '#111b54'
                ],
                'fill-outline-color': '#ffffff',
                'fill-opacity': 0.75
              },
            },
            hover: {
              type: 'fill',
              source: {
                type: 'geojson',
                data: geojson,
              },
              paint: {        
                'fill-outline-color': '#ffffff',
                'fill-color': '#111b54',
                'fill-opacity': 0.5,
              }
            },
            highlight: {
              type: 'line',
              source: {
                type: 'geojson',
                data: highlightedGeojson,
              },
              paint: {        
                'line-color': '#e03616',
                'line-width': 2
              }
            }
          }

        },
        e => {
          console.log('No map', e);
        }
      );
    } else {
      console.log('No map defined for this period.')
    }

  }

  mouseOver(event, type) {
    let code;
    
    // TODO: Comment code

    if (type === 'map') {
      code = event.features[0].properties[this.map.code];
    } else {
      code = event.target.code;
    }

    let chart = this.Highcharts.charts.find(c => c != undefined && c.renderTo.id === 'barChart'),
        point = chart.series[0].points.find(p => p.code === code);

    chart.series[0].points.forEach(p => p.setState(''));
    point.setState('hover');
    this.mbHover = ['==', this.map.code, code];
    this.hovered = this.nationalTable.find(t => t.code === code);
  }

  mouseLeave() {
    let chart = this.Highcharts.charts.find(c => c != undefined && c.renderTo.id === 'barChart');

    chart.series[0].points.forEach(p => p.setState(''));
    this.mbHover = ['==', this.map.code, ''];
    this.hovered = null;
  }

  // FORMATS (TODO: Move to service)

  formatTiers(tiers: any) {
    let searchableTiers = [];

    tiers.forEach(t1 => {
      searchableTiers.push({
      t0_tierId: t1.tierId,
      t0_tierName: t1.tierName,
      t0_isVisible: t1.isVisible,
      reportId: t1.reportId,
      });
      if (t1.childTiers) {
        t1.childTiers.forEach(t2 => {
          searchableTiers.push({
            t1_tierId: t1.tierId,
            t1_tierName: t1.tierName,
            t2_tierId: t2.tierId,
            t2_tierName: t2.tierName,
            t2_isVisible: t2.isVisible,
            reportId: t2.reportId,
          });
          if (t2.childTiers) {
            t2.childTiers.forEach(t3 => {
              searchableTiers.push({
                t1_tierId: t1.tierId,
                t1_tierName: t1.tierName,
                t2_tierId: t2.tierId,
                t2_tierName: t2.tierName,
                t2_isVisible: t2.isVisible,
                t3_tierId: t3.tierId,
                t3_tierName: t3.tierName,
                t3_isVisible: t3.isVisible,
                reportId: t3.reportId
              });
              if (t3.childTiers) {
                t3.childTiers.forEach(t4 => {
                  searchableTiers.push({
                    t1_tierId: t1.tierId,
                    t1_tierName: t1.tierName,
                    t2_tierId: t2.tierId,
                    t2_tierName: t2.tierName,
                    t2_isVisible: t2.isVisible,
                    t3_tierId: t3.tierId,
                    t3_tierName: t3.tierName,
                    t3_isVisible: t3.isVisible,
                    t4_tierId: t4.tierId,
                    t4_tierName: t4.tierName,
                    t4_isVisible: t4.isVisible,
                    reportId: t4.reportId
                  });
                })
              }
            })
          }
        })
      }
    });

    // Create tier map
    searchableTiers.forEach(o => {
      o.tierIdMap = [
        o.t0_tierId,
        o.t1_tierId ? o.t1_tierId : null,  
        o.t2_tierId ? o.t2_tierId : null,  
        o.t3_tierId ? o.t3_tierId : null, 
        o.t4_tierId ? o.t4_tierId : null
      ];
      o.tierNameMap = [
        o.t0_tierName,
        o.t1_tierName ? o.t1_tierName : null,
        o.t2_tierName ? o.t2_tierName : null,  
        o.t3_tierName ? o.t3_tierName : null, 
        o.t4_tierName ? o.t4_tierName : null
      ];
    });

    searchableTiers.forEach(o => {
      o.tierIdMap = o.tierIdMap.filter(t => t !== null);
      o.tierId = o.tierIdMap.filter(t => t !== null).pop();
      o.tierNameMap = o.tierNameMap.filter(t => t !== null);
    });

    // Remove null reports
    searchableTiers = searchableTiers.filter(t => t.reportId !== null);

    return searchableTiers;
  }

  formatFilters(reportFilters: any) {
    let formattedFilters: any;
    
    // Remove unknown data types
    formattedFilters = reportFilters.filter(rf => rf.dataType !== 'UK');

    // Add empty params
    formattedFilters.forEach(f => f.params = []);

    // Create primary and secondary filters
    formattedFilters = {
      allFilters: formattedFilters,
      primaryFilters: formattedFilters.filter(rf => rf.filterClassId === 1),
      secondaryFilters: formattedFilters.filter(rf => rf.filterClassId === 2)
    };

    // Set all primary filter selections to active
    if (formattedFilters.primaryFilters.length) {
      formattedFilters.primaryFilters.forEach(pf => {
        pf.active = pf.listItems.map(li => li.listItemId);
      });
    }

    return formattedFilters;
  }

  formatPeriods(minMaxDates, timeParam?) {
    var startDate = moment(minMaxDates.minDate, "YYYY-M-DD");
    var endMonth = moment(minMaxDates.maxDate, "YYYY-M-DD").endOf("month");
    var endYear = moment(minMaxDates.maxDate, "YYYY-M-DD").endOf("year");
    var months = [], years = [];
    var finalMonths = [], finalYears = [];

    // Create list of months
    while (startDate.isBefore(endMonth)) {
      months.push(startDate.format("YYYY-MM"));
      startDate = startDate.add(1, "month");
    };

    months.forEach(m => {
      let start = moment(m).startOf('month').format('YYYY-MM-DD');
      let end = moment(m).endOf('month').format('YYYY-MM-DD');
      finalMonths.push({
        periodType: 'month',
        periodFormat: 'LLLL yyyy',
        periodName: moment(m).format('MMMM YYYY'),
        periodStart: start,
        periodEnd: end,
        periodFy: this.getFY(start),
        periodParams: timeParam ? [
          {
            filterType: 'dateFrom',
            filterId: timeParam.dataItemId,
            filterValue: start
          },
          {
            filterType: 'dateTo',
            filterId: timeParam.dataItemId,
            filterValue: end
          },
        ] : null
      })
    })

    // Create list of years
    while (startDate.isBefore(endYear)) {
      years.push(startDate.format("YYYY"));
      startDate = startDate.add(1, "year");
    };

    years.forEach(y => {
      let start = moment(y).startOf('year').format('YYYY-MM-DD');
      let end = moment(y).endOf('year').format('YYYY-MM-DD');
      finalYears.push({
        periodType: 'year',
        periodFormat: 'yyyy',
        periodName: moment(y).format('YYYY'),
        periodStart: start,
        periodEnd: end,
        periodFy: this.getFY(start),
        periodParams: timeParam ? [
          {
            filterType: 'dateFrom',
            filterId: timeParam.dataItemId,
            filterValue: start
          },
          {
            filterType: 'dateTo',
            filterId: timeParam.dataItemId,
            filterValue: end
          },
        ] : null
      })
    });

    // Return as periods
    return {
      months: finalMonths.reverse(),
      years: finalYears.reverse()
    };
  }

  formatData(reportData: any, seriesName: string, startDate: number, endDate: number, timeIntervalType: any) {

    let dateStart = moment(startDate),
        dateEnd = moment(endDate),
        interim = dateStart.clone(),
        dateFormat, interimFormat,
        seriesData = [];

    switch (timeIntervalType) {
      case 'month':
        dateFormat = 'M';
        interimFormat = 'MMM-yy';
        break;
      case 'day':
        dateFormat = 'DD';
        interimFormat = 'DD-MMM-YY';
        break;
      default:
        console.log('No time interval type selected')
    }

    while (dateEnd > interim || interim.format(dateFormat) === dateEnd.format(dateFormat)) {
      seriesData.push({ 
        seriesName: seriesName,
        date: interim.format(interimFormat),
        caseCount: 0
      });
      interim.add(1, timeIntervalType);
    }

    seriesData.forEach(p => {
      reportData.forEach(rd => {
        if (p.date === rd.date) {
          p.caseCount = rd.caseCount;
        }
      })
    });
    
    return seriesData;
  }

  formatTimeSeries(reportData: any, timeIntervalType: any) {
    // Set date range
    let dates = reportData.map(d => moment(d.datePeriod));       
    this.dates.start = moment.min(dates).format('YYYY-MM-DD');
    this.dates.end = moment.max(dates).format('YYYY-MM-DD');
    
    // Format dates
    if (reportData.length) {
      switch (timeIntervalType) {
        case 'month':
          reportData.forEach(rd => { rd.date = moment(rd.datePeriod).format('MMM-yy') });
          break;
        case 'day':
          reportData.forEach(rd => { rd.date = moment(rd.datePeriod).format('DD-MMM-YY') });
          break;
        case 'hour':
          reportData.forEach(rd => { rd.date = moment(rd.datePeriod).format('HH:mm') });
          break;
        default:
          console.log('No time interval type selected')
      }
    } else {
      return;
    }

    // Group by series
    reportData = this.group.transform(reportData, 'seriesName');

    // Add missing dates
    Object.keys(reportData).forEach(rd => {
      reportData[rd] = this.formatData(reportData[rd], rd, this.dates.start, this.dates.end, timeIntervalType);
    })

    // Define categories
    let categories = reportData[Object.keys(reportData)[0]].map(rd => rd.date);
    
    // Define data
    let data = [];
    Object.keys(reportData).forEach(rd => {
      data.push({
        name: rd,
        data: reportData[rd].map(d => d.caseCount)
      })
    })

    return { categories: categories, data: data };
  }

  // UTILITIES

  getMapInfo() {
    let zoom = this.mapboxMap.getZoom();
    let center = this.mapboxMap.getCenter();
    console.log(zoom, center);
  }

  getFY(dateFrom) {
    let quarter = moment(dateFrom).quarter();
    let fyFrom = +moment(dateFrom).format('YYYY');
    let periodFy;
    if (quarter == 1) {
      periodFy = (fyFrom - 1).toString();
    } else {
      periodFy = fyFrom.toString();
    }
    return +periodFy;
  }

}
