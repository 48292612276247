<!-- ERROR -->
<div class="container-fluid" *ngIf="error">
  <div class="row justify-content-center">
    <div class="col-sm-10 col-12">
      <div class="alert alert-danger">
        {{ error }}
      </div>
    </div>
  </div>
</div>

<!-- LOADING -->
<div id="loading" class="text-center bg-success text-white px-3 py-2 rounded" *ngIf="loading">
  <h6>Loading report. Please wait...</h6>
  This may take a few seconds, as there can be a lot of data to retrieve.
</div>

<!-- BACKGROUND -->
<div class="dropdown-background" *ngIf="dropdown.any" (click)="setDropdown('any')"></div>

<!-- SELECTOR BAR -->
<div id="selectorBar">
  <div class="container-fluid">
    <div class="row justify-content-between">
      <div class="col-8">
        <ng-container *ngFor="let t of tiers">
          <span *ngIf="t.isVisible == 'Y'" (click)="setTier(t, 'top'); loading = true" [class]="tier && tier.tierId == t.tierId ? 'btn btn-info mr-2' : 'btn btn-outline-info mr-2'">
            {{ t.tierName }}
          </span>
        </ng-container>
        <ng-container *ngIf="tiers">
          <button class="btn btn-outline-info mr-2" disabled>Street Level Crime</button>
          <button class="btn btn-outline-info mr-2" disabled>Custody Suite</button>
        </ng-container>
      </div>
      <div class="col-4 text-right">
        <!-- Organisations -->
        <div class="dropdown-container -right">
          <button class="btn btn-outline-light" (click)="setDropdown('organisations')">
            {{ organisation ? organisation.organisationName : 'Select an organisation...' }}
          </button>
          <div class="dropdown" *ngIf="dropdown.organisations">
            <div *ngFor="let t1 of organisations" class="tier">
              <div (click)="t1.showChildren = !t1.showChildren">
                <ng-container *ngIf="t1.children.length">
                  <span [class]="t1.showChildren ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></span>
                </ng-container>
                <span [class]="organisation && t1.organisationId && t1.organisationId === organisation.organisationId ? 'active' : null">{{ t1.organisationName }}</span>
                <span *ngIf="t1.userHasRole" (click)="setOrganisation(t1)" class="badge badge-info ml-2 text-upper">Show Data</span>
              </div>
              <ng-container *ngIf="t1.children.length && t1.showChildren">
                <div *ngFor="let t2 of t1.children" class="tier">
                  <div (click)="t2.showChildren = !t2.showChildren">
                    <ng-container *ngIf="t2.children.length">
                      <span [class]="t2.showChildren ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></span>
                    </ng-container>
                    <span [class]="organisation && t2.organisationId && t2.organisationId === organisation.organisationId ? 'active' : null">{{ t2.organisationName }}</span>
                    <span *ngIf="t2.userHasRole" (click)="setOrganisation(t2)" class="badge badge-info ml-2 text-upper">Show Data</span>
                  </div>
                  <ng-container *ngIf="t2.children.length && t2.showChildren">
                    <div *ngFor="let t3 of t2.children" class="tier">
                      <div (click)="t3.showChildren = !t3.showChildren">
                        <ng-container *ngIf="t3.children.length">
                          <span [class]="t3.showChildren ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></span>
                        </ng-container>
                        <span [class]="organisation && t3.organisationId && t3.organisationId === organisation.organisationId ? 'active' : null">{{ t3.organisationName }}</span>
                        <span *ngIf="t3.userHasRole" (click)="setOrganisation(t3)" class="badge badge-info ml-2 text-upper">Show Data</span>
                      </div>
                      <ng-container *ngIf="t3.children.length && t3.showChildren">
                        <div *ngFor="let t4 of t3.children" class="tier">
                          <div (click)="t4.showChildren = !t4.showChildren">
                            <ng-container *ngIf="t4.children.length">
                              <span [class]="t4.showChildren ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></span>
                            </ng-container>
                            <span [class]="organisation && t4.organisationId && t4.organisationId === organisation.organisationId ? 'active' : null">{{ t4.organisationName }}</span>
                            <span *ngIf="t4.userHasRole" (click)="setOrganisation(t4)" class="badge badge-info ml-2 text-upper">Show Data</span>
                          </div>
                          <ng-container *ngIf="t4.children.length && t4.showChildren">
                            <div *ngFor="let t5 of t4.children" class="tier">
                              <div (click)="t5.showChildren = !t5.showChildren">
                                <ng-container *ngIf="t5.children.length">
                                  <span [class]="t5.showChildren ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></span>
                                </ng-container>
                                <span [class]="organisation && t5.organisationId && t5.organisationId === organisation.organisationId ? 'active' : null">{{ t5.organisationName }}</span>
                                <span *ngIf="t5.userHasRole" (click)="setOrganisation(t5)" class="badge badge-info ml-2 text-upper">Show Data</span>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <span class="btn btn-info ml-2" *ngIf="details" (click)="setDropdown('drawer')">
          <i class="fas fa-arrow-alt-from-right mr-2"></i>Show Options
        </span>
      </div>
    </div>
  </div>
</div>

<!-- TIERS, CATEGORIES, PERIODS & FILTERS -->
<div class="container-fluid">
  <div class="row justify-content-between my-3" *ngIf="tiers">
    <div class="col-12">

      <!-- Tiers (traditional, not national)
      <div class="dropdown-container">
        <button class="btn btn-outline-primary" (click)="setDropdown('tiers')">
          <i class="fas fa-bars mr-2"></i>{{ details ? details.reportName : 'Select a report...' }}
        </button>
        <div class="dropdown" *ngIf="dropdown.tiers">
          <div *ngFor="let t1 of tiers" class="tier">
            <div (click)="t1.reportId ? getDetails(t1.reportId, t1) : t1.showChildren = !t1.showChildren">
              <ng-container *ngIf="t1.childTiers">
                <span [class]="t1.showChildren ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></span>
              </ng-container>
              <span *ngIf="t1.isVisible == 'Y'" [class]="details && t1.reportId && t1.reportId === details.reportId ? 'active' : null">{{ t1.tierName }}</span>
            </div>
            <ng-container *ngIf="t1.childTiers && t1.showChildren">
              <div *ngFor="let t2 of t1.childTiers" class="tier">
                <div (click)="t2.reportId ? getDetails(t2.reportId, t2) : t2.showChildren = !t2.showChildren">
                  <span *ngIf="t2.isVisible == 'Y'"  [class]="details && t2.reportId && t2.reportId === details.reportId ? 'active' : null">{{ t2.tierName }}</span>
                </div>
                <ng-container *ngIf="t2.childTiers && t2.showChildren">
                  <div *ngFor="let t3 of t2.childTiers" class="tier">
                    <div (click)="t3.reportId ? getDetails(t3.reportId, t3) : null">
                      <span *ngIf="t3.isVisible == 'Y'" [class]="details && t3.reportId && t3.reportId === details.reportId ? 'active' : null">{{ t3.tierName }}</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div> -->

      <!-- (Old) Categories, Periods and Filters
      <ng-container *ngIf="filters && filters.primaryFilters">
        <div class="dropdown-container" *ngFor="let filter of filters.primaryFilters">
          <button class="btn btn-outline-primary" (click)="setDropdown('categories'); setFilter(filter.dataItemId)">
            {{ filter.filterName }}
          </button>
          <div class="dropdown" *ngIf="dropdown.categories">          
            <div *ngFor="let item of filter.listItems" (click)="setFilterValue(filter, 'listItem', filter.dataItemId, item.listItemId)" class="selector mb-1">
              <span [class]="filter.active && filter.active.includes(item.listItemId) ? 'far fa-check-square mr-2' : 'far fa-square mr-2'"></span>{{ item.listItemName }}
            </div>
            <div class="mt-2">
              <button *ngIf="setFilters.length" class="btn btn-success mt-2" (click)="setData(reportId, organisation.organisationId, period, paramFilters)">Update</button>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="dropdown-container" *ngIf="periods">
        <button class="btn btn-outline-primary" (click)="setDropdown('periods')">
          {{ period ? period.periodName : 'Periods' }}
        </button>
        <div class="dropdown" *ngIf="dropdown.periods">             
          <h5>Months</h5>
          <div>
            <span *ngFor="let month of periods.months" [class]="period.periodName === month.periodName ? 'btn btn-sm btn-success mr-1 mb-1' : 'btn btn-sm btn-outline-secondary mr-1 mb-1'" (click)="setPeriod(month)">{{ month.periodName }}</span>
          </div>
          <h5>Years</h5>
          <div>
            <span *ngFor="let year of periods.years" [class]="period.periodName === year.periodName ? 'btn btn-sm btn-success mr-1 mb-1' : 'btn btn-sm btn-outline-secondary mr-1 mb-1'" (click)="setPeriod(year)">{{ year.periodName }}</span>
          </div>
        </div>
      </div>

      <div class="dropdown-container" *ngIf="filters && filters.secondaryFilters">
        <button [class]="filterCount ? 'btn btn-primary' : 'btn btn-outline-primary'" (click)="setDropdown('filters')">
          Filters <span *ngIf="filterCount > 0" class="badge badge-info ml-1">{{ filterCount }}</span>
        </button>
        <div class="dropdown" *ngIf="dropdown.filters">
          <p>
            Add filters by selecting them from the drop-down list below. You can add multiple filters. When you have added the filters you need, click 'Update' to refresh the data.
          </p>
          <div class="form-group">
            <select name="filters" class="form-control" (change)="setFilter($event.target.value)">
              <option disabled selected>Select a filter to add...</option>
              <option *ngFor="let filter of filters.secondaryFilters" [value]="filter.dataItemId">{{ filter.filterName }} ({{ filter.dataType }})</option>
            </select>
          </div>
          <div *ngFor="let filter of setFilters" class="card mb-2">
            <div *ngIf="filter.filterClassId == 2" class="card-body">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="destroyFilter(filter)">
                <span aria-hidden="true">&times;</span>
              </button>
              <h5 class="card-title">{{ filter.filterName }}</h5>
              <div [ngSwitch]="filter.dataType">

                // List
                <div *ngSwitchCase="'LS'">
                  <span *ngFor="let item of filter.listItems" [class]="filter.active && filter.active.includes(item.listItemId) ? 'btn btn-sm btn-success mr-1 mb-1' : 'btn btn-sm btn-outline-secondary mr-1 mb-1'" (click)="setFilterValue(filter, 'listItem', filter.dataItemId, item.listItemId)">{{ item.listItemName }}</span>
                </div>

                // Yes/No
                <div *ngSwitchCase="'YN'">
                  <span *ngFor="let item of [{ listItemId: 'Y', listItemName: 'Yes' }, { listItemId: 'N', listItemName: 'No' }]" [class]="filter.active && filter.active.includes(item.listItemId) ? 'btn btn-sm btn-success mr-1 mb-1' : 'btn btn-sm btn-outline-secondary mr-1 mb-1'" (click)="setFilterValue(filter, 'yesNo', filter.dataItemId, item.listItemId)">{{ item.listItemName }}</span>
                </div>

                // Date
                <div *ngSwitchCase="'DT'">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="dateFrom">From:</label>
                        <input type="date" (input)="setFilterValue(filter, 'dateFrom', filter.dataItemId, $event.target.value)" class="form-control" name="dateFrom" [value]="filter.params && filter.params[0] ? filter.params[0].filterValue : null" />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label for="dateTo">To:</label>
                        <input type="date" (input)="setFilterValue(filter, 'dateTo', filter.dataItemId, $event.target.value)" class="form-control" name="dateTo" [value]="filter.params && filter.params[1] ? filter.params[1].filterValue : null" />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <button class="btn btn-success mt-2" (click)="setData(details.reportId, organisation.organisationId, period, paramFilters)">Update</button>
          <span *ngIf="!setFilters.length" class="text-muted btn pt-3">
            No filters selected.
          </span>
        </div>
      </div> -->

    </div>
  </div>
</div>

<!-- DRAWER -->
<div id="drawer" *ngIf="dropdown.drawer" [@slideInOut]>
  
  <!-- Benchmark -->
  <ng-container>
    <div class="drawer-section">
      <h4>Benchmark</h4>
      <p class="drawer-help">Choose to benchmark the data against Police Force area population or workforce numbers.</p>
      <span *ngFor="let b of benchmarks" [class]="reportId === b.reportId ? 'btn btn-sm btn-primary mr-1 mb-1' : 'btn btn-sm btn-outline-primary mr-1 mb-1'" (click)="setReport(b)">{{ b.tierName }}</span>
    </div>
  </ng-container>

  <!-- Periods -->
  <ng-container *ngIf="periods">
    <div class="drawer-section">
      <h4>Periods</h4>
      <p class="drawer-help">Select the time periods for which you would like to view the data. You can see either a monthly or annual view.</p>
      <div>
        <span *ngFor="let month of periods.months" [class]="period.periodName === month.periodName ? 'btn btn-sm btn-primary mr-1 mb-1' : 'btn btn-sm btn-outline-secondary mr-1 mb-1'" (click)="setPeriod(month)">{{ month.periodName }}</span>
      </div>
      <div>
        <span *ngFor="let year of periods.years" [class]="period.periodName === year.periodName ? 'btn btn-sm btn-primary mr-1 mb-1' : 'btn btn-sm btn-outline-secondary mr-1 mb-1'" (click)="setPeriod(year)">{{ year.periodName }}</span>
      </div>
    </div>
  </ng-container>

  <!-- Categories -->
  <ng-container *ngIf="filters && filters.primaryFilters">
    <div class="drawer-section" *ngFor="let filter of filters.primaryFilters">
      <h4>{{ filter.filterName }}</h4>
      <p class="drawer-help">Select the category or categories of data you would like to see for this report.</p>
      <span *ngFor="let item of filter.listItems" (click)="setFilter(filter.dataItemId); setFilterValue(filter, 'listItem', filter.dataItemId, item.listItemId)" [class]="filter.active && filter.active.includes(item.listItemId) ? 'btn btn-sm btn-success mr-1 mb-1' : 'btn btn-sm btn-outline-secondary mr-1 mb-1'">{{ item.listItemName }}</span>
    </div>
  </ng-container>

  <!-- Filters -->  
  <ng-container *ngIf="filters && filters.secondaryFilters">
    <div class="drawer-section" >
      <h4>Filters</h4>
      <p class="drawer-help">Add optional filters to the data. You can add as many filters as you like.</p>
      <ng-container *ngFor="let filter of setFilters">
        <div *ngIf="filter.filterClassId == 2" class="card mb-2">
          <div class="card-body">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="destroyFilter(filter)">
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="card-title">{{ filter.filterName }}</h5>
            <div [ngSwitch]="filter.dataType">
  
              <!-- List -->
              <div *ngSwitchCase="'LS'">
                <span *ngFor="let item of filter.listItems" [class]="filter.active && filter.active.includes(item.listItemId) ? 'btn btn-sm btn-success mr-1 mb-1' : 'btn btn-sm btn-outline-secondary mr-1 mb-1'" (click)="setFilterValue(filter, 'listItem', filter.dataItemId, item.listItemId)">{{ item.listItemName }}</span>
              </div>
  
              <!-- Yes/No -->
              <div *ngSwitchCase="'YN'">
                <span *ngFor="let item of [{ listItemId: 'Y', listItemName: 'Yes' }, { listItemId: 'N', listItemName: 'No' }]" [class]="filter.active && filter.active.includes(item.listItemId) ? 'btn btn-sm btn-success mr-1 mb-1' : 'btn btn-sm btn-outline-secondary mr-1 mb-1'" (click)="setFilterValue(filter, 'yesNo', filter.dataItemId, item.listItemId)">{{ item.listItemName }}</span>
              </div>
  
              <!-- Date -->
              <div *ngSwitchCase="'DT'">
                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="dateFrom">From:</label>
                      <input type="date" (input)="setFilterValue(filter, 'dateFrom', filter.dataItemId, $event.target.value)" class="form-control" name="dateFrom" [value]="filter.params && filter.params[0] ? filter.params[0].filterValue : null" />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="dateTo">To:</label>
                      <input type="date" (input)="setFilterValue(filter, 'dateTo', filter.dataItemId, $event.target.value)" class="form-control" name="dateTo" [value]="filter.params && filter.params[1] ? filter.params[1].filterValue : null" />
                    </div>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
        </div>
      </ng-container>
      <div class="form-group">
        <select name="filters" class="form-control" (change)="setFilter($event.target.value)">
          <option disabled selected>Select a filter to add...</option>
          <option *ngFor="let filter of filters.secondaryFilters" [value]="filter.dataItemId">{{ filter.filterName }} ({{ filter.dataType }})</option>
        </select>
      </div>
    </div>
  </ng-container>

  <!-- Actions -->
  <div id="drawerAction">
    <button class="btn btn-success" (click)="setData(reportId, organisation.organisationId, period, paramFilters)">
      Update Report
    </button>
    <button class="btn btn-outline-secondary ml-2" (click)="destroyDrawer(set)">Close</button>
  </div>

</div>

<!-- REPORT -->
<div class="container-fluid position-relative" *ngIf="!error">
  <div id="hovered" *ngIf="hovered">
    <strong>{{ hovered.organisationName }}</strong><br>
    {{ hovered.y | number: '1.0-2' }}
  </div>
  <div class="row justify-content-between mb-2" *ngIf="details">
    <div class="col-12">
      <!-- Title -->
      <h2>
        {{ tier.tierName }}<br>
        <small>
          <span><strong>{{ benchmark?.tierName }}</strong></span>
          <span *ngIf="period" class="ml-1">&bull; {{ set.period.periodName }}</span>
          <span *ngIf="set.filterCount" class="ml-1">&bull; {{ set.filterCount }} {{ 'filter' | makePluralString: set.filterCount }} applied</span>
        </small>
      </h2>
    </div>
  </div>
  <div class="row" *ngIf="details">
    <div class="col-8">
      <div *ngIf="details && loading_national" class="loading-panel">
        <i class="far fa-spinner fa-pulse"></i>
      </div>
      <highcharts-chart *ngIf="!loading_national && columnChartOptions" id="barChart" [Highcharts]="Highcharts" [options]="columnChartOptions" class="height-400"></highcharts-chart>
    </div>
    <div class="col-4">
      <div *ngIf="details && loading_map" class="loading-panel">
        <i class="far fa-spinner fa-pulse"></i>
      </div>
      <ng-container *ngIf="!loading_map && mapboxOptions">
        <mgl-map
        [style]="mapboxOptions.style" 
        [zoom]="mapboxOptions.zoom" 
        [center]="mapboxOptions.center"
        [logoPosition]="mapboxOptions.logoPosition"
        [fitBounds]="mapboxOptions.bounds"
        [fitBoundsOptions]="mapboxOptions.boundsOptions"
        (load)="mapboxMap = $event">
        <mgl-layer
          id="organisations"
          [type]="mapboxOptions.organisations.type"
          [source]="mapboxOptions.organisations.source"
          [paint]="mapboxOptions.organisations.paint"
          (mouseMove)="mouseOver($event, 'map')"
          (mouseLeave)="mouseLeave()">
        </mgl-layer>
        <mgl-layer
          id="organisations-hover"
          [type]="mapboxOptions.hover.type"
          [source]="mapboxOptions.organisations.source"
          [paint]="mapboxOptions.hover.paint"
          [filter]="mbHover">
        </mgl-layer>
        <mgl-layer
          id="highlight"
          [type]="mapboxOptions.highlight.type"
          [source]="mapboxOptions.highlight.source"
          [paint]="mapboxOptions.highlight.paint">
        </mgl-layer>
      </mgl-map>
      </ng-container>
    </div>
  </div>
  <div class="row mt-4" *ngIf="details">
    <div class="col-5">
      <div *ngIf="details && loading_timeseries" class="loading-panel">
        <i class="far fa-spinner fa-pulse"></i>
      </div>
      <ng-container *ngIf="!loading_timeseries && timeSeriesChartOptions">
        <h4>Time Series</h4>
        <highcharts-chart id="lineChart" [Highcharts]="Highcharts" [options]="timeSeriesChartOptions" class="height-400"></highcharts-chart>
      </ng-container>
    </div>
    <div class="col-7">
      <div *ngIf="details && loading_split" class="loading-panel">
        <i class="far fa-spinner fa-pulse"></i>
      </div>
      <ng-container *ngIf="!loading_split && splitChartOptions">
        <div [style.opacity]="loading_split_change ? 0.5 : 1">
          <h4>Percentage Split by Filter</h4>
          <button *ngFor="let s of splits" [class]="s.split ? 'btn btn-sm btn-primary mb-2 mr-2' : 'btn btn-sm btn-outline-primary mb-2 mr-2'" (click)="setSplit(s, organisation.organisationId, period, paramFilters, true)">{{ s.filterName }}</button>
          <highcharts-chart id="splitChart" [Highcharts]="Highcharts" [options]="splitChartOptions" class="height-360"></highcharts-chart>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row mt-4" *ngIf="table">
    <div class="col-5" *ngIf="!loading_timeseries">
      <h4>Time Series Data Table</h4>
      <div class="table-responsive-md">
        <table class="table table-sm">
          <thead class="thead-light">
            <tr>
              <th scope="col" style="width:25%">Period</th>
              <th scope="col" style="width:37.5%">National Average</th>
              <th scope="col" style="width:37.5%">{{ organisation.organisationName }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let t of table">
              <td>{{ t.period }}</td>
              <td>{{ t.national | number: '1.0-2' }}</td>
              <td>{{ t.organisation | number: '1.0-2' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-7" *ngIf="!loading_national">
      <h4>Data Table for {{ period.periodName }} </h4>
      <div class="table-responsive-md">
        <table class="table table-sm">
          <thead class="thead-light">
            <tr>
              <th scope="col" style="width:25%">Code</th>
              <th scope="col" style="width:50%">Organisation</th>
              <th scope="col" style="width:25%">Result</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let t of nationalTable">
              <td>
                <span class="fas fa-square mr-1" [style.color]="t.color"></span>
                {{ t.code }}
              </td>
              <td>{{ t.organisationName }}</td>
              <td>{{ t.y | number: '1.0-2' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
