<div *ngIf="permissionsError">
    <div class="container-fluid mt-5">
        <div class="row justify-content-center">
            <div class="col-sm-8">
                <div class="alert alert-danger text-center">
                    You do not currently have the necessary permissions to view the dashboard and toolkit. Please contact support.
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="organisation && !permissionsError">
    <div class="bg-primary p-2">
        <div class="container-fluid">
            <div class="row justify-content-end">

                <!-- Organisation Tiers -->
                <div class="col-lg-4 col-md-6 col-sm-10 col-12" *ngIf="organisations && organisations.length > 1">

                    <div class="position-relative">
                        <button class="btn btn-outline-secondary btn-block text-left" (click)="getOrganisationTiers(1); organisationMenu = !organisationMenu" [disabled]="reportLoading">
                            {{ organisation ? organisation.organisationName : 'Select organisation...'}}
                            <i [class]="organisationMenu ? 'fas fa-angle-up ml-2' : 'fas fa-angle-down ml-2'"></i>
                        </button>
                        <div id="organisationMenu" *ngIf="organisationMenu" class="position-absolute mt-3 p-3 bg-white rounded border border-secondary shadow">
        
                            <div *ngFor="let o1 of organisationTiers" class="mb-2">
                                <!-- Tier 1 -->
                                <div (click)="o1.open = !o1.open" class="org-tier">
                                    <div class="row">
                                        <div class="col-1 text-center">
                                            <ng-container *ngIf="o1.children.length > 0">
                                                <i [class]="o1.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                            </ng-container>
                                            <ng-container *ngIf="o1.children.length == 0">
                                                <i class="fas fa-caret-down fa-fw" style="color:transparent"></i>
                                            </ng-container>
                                        </div>
                                        <div [class]="o1.organisationId === organisation.organisationId ? 'col-10 selected' : 'col-10'">
                                            <span [class]="o1.userHasRole ? 'link' : 'text-muted'">
                                                {{ o1.organisationName }}&nbsp;
                                                <i *ngIf="o1.userHasRole" class="org-tier-select" (click)="updateOrganisation(o1.organisationId)">Show&nbsp;Data</i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="o1.open">
                                    <!-- Tier 2 -->
                                    <div *ngFor="let o2 of o1.children" class="ml-3">
                                        <div (click)="o2.open = !o2.open" class="org-tier">
                                            <div class="row">
                                                <div class="col-1 text-center">
                                                    <ng-container *ngIf="o2.children.length > 0">
                                                        <i [class]="o2.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                                    </ng-container>
                                                    <ng-container *ngIf="o2.children.length == 0">
                                                        <i class="fas fa-caret-down fa-fw" style="color:transparent"></i>
                                                    </ng-container>
                                                </div>
                                                <div [class]="o2.organisationId === organisation.organisationId ? 'col-10 selected' : 'col-10'">
                                                    <span [class]="o2.userHasRole ? 'link' : 'text-muted'">
                                                        {{ o2.organisationName }}&nbsp;
                                                        <i *ngIf="o2.userHasRole" class="org-tier-select" (click)="updateOrganisation(o2.organisationId)">Show&nbsp;Data</i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="o2.open">
                                            <!-- Tier 3 -->
                                            <div *ngFor="let o3 of o2.children" class="ml-3">
                                                <div (click)="o3.open = !o3.open" class="org-tier">
                                                    <div class="row">
                                                        <div class="col-1 text-center">
                                                            <ng-container *ngIf="o3.children.length > 0">
                                                                <i [class]="o3.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                                            </ng-container>
                                                            <ng-container *ngIf="o3.children.length == 0">
                                                                <i class="fas fa-caret-down fa-fw" style="color:transparent"></i>
                                                            </ng-container>
                                                        </div>
                                                        <div [class]="o3.organisationId === organisation.organisationId ? 'col-10 selected' : 'col-10'">
                                                            <span [class]="o3.userHasRole ? 'link' : 'text-muted'">
                                                                {{ o3.organisationName }}&nbsp;
                                                                <i *ngIf="o3.userHasRole" class="org-tier-select" (click)="updateOrganisation(o3.organisationId)">Show&nbsp;Data</i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="o3.open">
                                                    <!-- Tier 4 -->
                                                    <div *ngFor="let o4 of o3.children" class="ml-3">
                                                        <div (click)="o4.open = !o4.open" class="org-tier">
                                                            <div class="row">
                                                                <div class="col-1 text-center">
                                                                    <ng-container *ngIf="o4.children.length > 0">
                                                                        <i [class]="o4.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="o4.children.length == 0">
                                                                        <i class="fas fa-caret-down fa-fw" style="color:transparent"></i>
                                                                    </ng-container>
                                                                </div>
                                                                <div [class]="o4.organisationId === organisation.organisationId ? 'col-10 selected' : 'col-10'">
                                                                    <span [class]="o4.userHasRole ? 'link' : 'text-muted'">
                                                                        {{ o4.organisationName }}&nbsp;
                                                                        <i *ngIf="o4.userHasRole" class="org-tier-select" (click)="updateOrganisation(o4.organisationId)">Show&nbsp;Data</i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="o4.open">
                                                        <!-- Tier 5 -->
                                                        <div *ngFor="let o5 of o4.children" class="ml-3">
                                                            <div (click)="o5.open = !o5.open" class="org-tier">
                                                                <div class="row">
                                                                    <div class="col-1 text-center">
                                                                        <ng-container *ngIf="o5.children.length > 0">
                                                                            <i [class]="o5.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="o5.children.length == 0">
                                                                            <i class="fas fa-caret-down fa-fw" style="color:transparent"></i>
                                                                        </ng-container>
                                                                    </div>
                                                                    <div [class]="o5.organisationId === organisation.organisationId ? 'col-10 selected' : 'col-10'">
                                                                        <span [class]="o5.userHasRole ? 'link' : 'text-muted'">
                                                                            {{ o5.organisationName }}&nbsp;
                                                                            <i *ngIf="o5.userHasRole" class="org-tier-select" (click)="updateOrganisation(o5.organisationId)">Show&nbsp;Data</i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                        </div>
                    </div>
        
                </div>

                <!-- Dashboard Report Tiers -->
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <select (change)="updateTier($event.target.value)" class="form-control bg-primary text-secondary border-secondary" [disabled]="reportLoading">
                        <option *ngFor="let t of tiers" [value]="t.tierId" [selected]="t.reportId === tier.reportId">
                            {{ t.tierName }}
                        </option>
                    </select>
                </div>

            </div>
        </div>
    </div>
    <div class="container-fluid">

        <div class="row justify-content-center">
            <div class="col-md-10 col-12">

                <!-- Welcome -->
                <div class="row justify-content-center mt-5">
                    <div class="col-md-8 text-center">
                        <h2>Welcome to the Mountain Healthcare Reporting&nbsp;Tool</h2>
                        <h3 class="text-info">{{ organisation ? organisation.organisationName + ' Dashboard' : 'No Dashboard Available' }}</h3>
                    </div>
                </div>
                
                <!-- Loading -->
                <div class="row justify-content-center mt-5" *ngIf="!reportData && !reportError">
                    <div class="col-md-8">
                        <div class="alert alert-primary text-center">
                            Loading Dashboard...
                        </div>
                    </div>
                </div>    

                <!-- Error -->
                <div class="row justify-content-center mt-5" *ngIf="reportError">
                    <div class="col-md-8">
                        <div class="alert alert-danger text-center">
                            {{ reportError }}
                        </div>
                    </div>
                </div>    
        
                <!-- Overview Stats -->
                <div class="row justify-content-center mt-3" *ngIf="reportData" [style.opacity]="reportLoading ? 0.25 : 1">
                    <div class="col-md-3"  *ngIf="reportStats && reportStats.latestMonth">
                        <div class="tile">
                            <h1>{{ reportStats.latestMonthCaseCount }}</h1>
                            <p>total count for {{ reportStats.latestMonth }}</p>
                        </div>
                    </div>
                    <div class="col-md-3" *ngIf="reportStats && reportStats.monthDiff">
                        <div class="tile">
                            <h1>
                                <ng-container *ngIf="reportStats.monthDiff != 'Infinity'">
                                    <i [class]="reportStats.monthStatus === 'increase' ? 'fas fa-caret-up fa-fw' : 'fas fa-caret-down fa-fw'"></i>
                                    {{ reportStats.monthDiff | number: '1.0-1' }}%
                                </ng-container>
                                <ng-container *ngIf="reportStats.monthDiff == 'Infinity'">
                                    n/a
                                </ng-container>
                            </h1>
                            <p>month-on-month change</p>
                        </div>
                    </div>
                    <div class="col-md-3" *ngIf="reportStats && reportStats.yearDiff">
                        <div class="tile">
                            <h1>
                                <ng-container *ngIf="reportStats.yearDiff != 'Infinity'">
                                    <i [class]="reportStats.yearStatus === 'increase' ? 'fas fa-caret-up fa-fw' : 'fas fa-caret-down fa-fw'"></i>
                                    {{ reportStats.yearDiff | number: '1.0-1' }}%
                                </ng-container>
                                <ng-container *ngIf="reportStats.yearDiff == 'Infinity'">
                                    n/a
                                </ng-container>
                            </h1>
                            <p>year-on-year change</p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="tile -link" [routerLink]="['/toolkit']">
                            <h1>
                                <i class="far fa-chart-bar"></i>
                            </h1>
                            <p>Operational Toolkit<i class="fas fa-caret-right ml-2"></i></p>
                        </div>
                    </div>
                </div>
        
                <div class="row justify-content-center" *ngIf="reportData">
                    <div class="col-12">
                        <div class="chart">
                            <div class="alert alert-primary text-center alert-report" *ngIf="reportLoading">
                                Loading report...
                            </div>
                            <div [style.opacity]="reportLoading ? 0.25 : 1">
                                <div class="row">
                                    <div class="col-6">
                                    <h5>
                                        {{ tier.tierName }}<br>
                                        <small>
                                        <ng-container *ngIf="!noReportData">
                                            between {{ startDate | date: 'd MMMM y' }} and {{ endDate | date: 'd MMMM y' }}
                                        </ng-container>
                                        <ng-container *ngIf="noReportData">
                                            <span class="text-danger">There is no data for this filter combination</span>
                                        </ng-container>
                                        </small>
                                    </h5>
                                    </div>
                                    <div class="col-6 text-right">
                                        <div class="btn btn-sm btn-outline-secondary mb-2" *ngIf="timeIntervalType === 'day' && !reportLoading" (click)="reset()">
                                            <i class="fas fa-redo mr-2"></i>Reset
                                        </div>
                                        <div class="btn btn-sm btn-outline-primary ml-2 mb-2" *ngIf="timeIntervalType === 'day'">
                                            <span *ngIf="loadingCaseRefs"><i class="fas fa-spinner fa-spin mr-1"></i>Loading case references</span>
                                            <span *ngIf="!loadingCaseRefs">Click on a day to retrieve case references</span>
                                        </div>
                                    </div>
                                </div>
                                <highcharts-chart [Highcharts]="Highcharts" [options]="timeSeriesChartOptions" (click)="updateTimeSeries($event, timeIntervalType)"></highcharts-chart>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>

<!-- Case Data -->
<div *ngIf="caseData" class="card-fixed-container">
    <div class="card card-fixed text-white bg-primary">
        <div class="position-relative">
            <div class="closer" (click)="caseData = null">&times;</div>
            <h4 class="mb-3">
                Case Notes for <strong>{{ caseDataDate | date: 'd MMMM yyyy' }}</strong>
            </h4>
        </div>
        <table class="table table-sm text-white">
            <thead>
                <tr>
                    <th>Case Reference</th>
                    <th>Response</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let c of caseData">
                    <td>{{ c.caseReference }}</td>
                    <td>{{ c.response }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>