import { Injectable } from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService {
  constructor(public jwtHelper: JwtHelperService) { }

  public authenticated() {
    return !this.jwtHelper.isTokenExpired();
  }

  public decode(token) {
    return this.jwtHelper.decodeToken(token);
  }

}
