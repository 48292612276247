<div class="d-flex flex-column">

  <!-- Nav -->
  <nav class="navbar navbar-expand-sm bg-white" *ngIf="user.isAuthenticated() && component !== 'LoginComponent'">
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="['/']">
        <img src="/assets/mark_beta.png" height="30" class="d-inline-block align-top mr-2" alt="Mountain Healthcare Mark">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i class="far fa-bars" (click)="showNav = !showNav"></i>
      </button>
    
      <div class="collapse navbar-collapse" [class.show]="showNav" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" [class.active]="router.isActive('/welcome', false)">
            <a class="nav-link" [routerLink]="['/welcome']"><i class="far fa-tachometer-slow mr-1 text-secondary"></i>Dashboard</a>
          </li>
          <li class="nav-item" [class.active]="router.isActive('/toolkit', false)">
            <a class="nav-link" [routerLink]="['/toolkit']"><i class="far fa-chart-bar mr-1 text-secondary"></i>Operational Toolkit</a>
          </li>
        </ul>
        <a class="nav-link" [routerLink]="['/about']"><i class="far fa-info-circle mr-1 text-secondary"></i>About</a>
        <a class="nav-link" [routerLink]="" (click)="linkToFeedbackForm()"><i class="far fa-comment-alt mr-1 text-secondary"></i>Feedback</a>
        <a class="nav-link" [routerLink]="" (click)="logout()"><i class="far fa-sign-out-alt mr-1 text-secondary"></i>Logout</a>
      </div>
    </div>
  </nav>

  <main>
    <router-outlet></router-outlet>
  </main>

</div>